import {getSessionToken} from '../http/auth'
import {API} from '../http/api'
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();
function getFormattedString(string) {
    let result = string.replace(/([A-Z])/g, " $1");
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    // let formattedString = finalResult.replace(/\W+/g, " ").split(/ |\B(?=[A-Z])/).join('_');
    let formattedString = finalResult.replace(" ", "");
    return formattedString
}

const performance = {
    namespaced: true,
    state: {
        loadingSummary: false,
        loadingFinancial: false,
        loadingImpact: false,
        clientPerformance: {},
        pdfPerformanceReturns: {
            portfolio: {
                cumulative: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                },
                rolling: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                }
            },
            benchmark: {
                cumulative: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                },
                rolling: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                }
            },
        },
        impactSummary: [],
        summaryError: false,
        impactError: false,
        impactDetailError:false,
        loadingImpactDetail: false,
        impactDetail: {
            portfolio: {
                companies: [],
                find: "",
                order: "asc",
                orderBy: "",
                page: 1,
                perPage: 20,
                total: 1
            },
            benchmark: {
                companies: [],
                find: "",
                order: "asc",
                orderBy: "",
                page: 1,
                perPage: 20,
                total: 1
            }
        },
        loadingImpactDetailResults: false
    },
    mutations: {
        setLoadingSummary(state, payload) {
            state.loadingSummary = payload;
        },
        setLoadingFinancial(state, payload) {
            state.loadingFinancial = payload;
        },
        setLoadingImpact(state, payload) {
            state.loadingImpact = payload;
        },
        setDailyPerformanceData(state, payload) {
            state.clientPerformance.DailyPerformanceData = payload
        },
        setPortfolioGeneralData(state, payload) {
            state.clientPerformance.PortfolioGeneralData = payload;
        },
        setPortfolioPerformanceData(state, payload) {
            state.clientPerformance.PortfolioPerformanceData = payload;
        },
        setImpactIndicatorsData(state, payload) {
            state.clientPerformance.ImpactIndicatorsData = payload;
        },
        setPdfPerformanceReturns(state, payload) {
            state.pdfPerformanceReturns = payload;
        },
        setClientPerformanceData(state, payload) {
            state.clientPerformance = payload;
        },
        setSummaryError(state, payload) {
            state.summaryError = payload;
        },
        setImpactError(state, payload){
            state.impactError = payload;
        },
        setImpactIndicatorsSummaryData(state, payload) {
            state.impactSummary = payload;
        },
        setImpactDetailPortfolio(state, payload) {
            state.impactDetail.portfolio = JSON.parse(JSON.stringify(payload));
        },
        setImpactDetailBenchmark(state, payload) {
            state.impactDetail.benchmark = JSON.parse(JSON.stringify(payload));
        },
        setImpactDetailError(state, payload) {
            state.impactDetailError = payload;
        },
        setLoadingImpactDetail(state, payload) {
            state.loadingImpactDetail = payload;
        },
        setLoadingImpactDetailResults(state, payload) {
            state.loadingImpactDetailResults = payload;
        }
    },
    actions: {
        async getDailyPerformanceData({ commit, dispatch }, data){
            if(data === undefined || data.selectedClient === undefined || data.selectedClient.id === undefined) {
                return;
            }
            commit("setLoadingFinancial", true);
            let fromDate = data.fromDate instanceof Date ? (data.fromDate.getFullYear() + '-' + (data.fromDate.getMonth() + 1) + '-' + data.fromDate.getDate()) : data.fromDate;
            let toDate = data.toDate instanceof Date ? (data.toDate.getFullYear() + '-' + (data.toDate.getMonth() + 1) + '-' + data.toDate.getDate()) : data.toDate;
            API.get(
                'getDailyPerformance'+(await getSessionToken() === null ? 'Shared' : ''),
                data.selectedClient.id + (fromDate ? '?from=' + (fromDate) : '') + (toDate ? '&to=' + (toDate) : ''),
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    let results = {};
                    results.data = reply;
                    results.fromDate = data.fromDate;
                    results.toDate = data.toDate;
                    commit("setDailyPerformanceData", results);
                } else {
                    toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                }
                commit("setLoadingFinancial", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setLoadingFinancial", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async getPortfolioGeneralData({ commit, dispatch }, data){
            if(data === undefined || data.selectedClient === undefined || data.selectedClient.id === undefined) {
                return;
            }
            commit("setLoadingFinancial", true);
            API.get(
                'getPortfolioByClientV2'+(await getSessionToken() === null ? 'Shared' : ''),
                data.selectedClient.id,
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    let results = {};
                    results.data = reply;
                    commit("setPortfolioGeneralData", results);
                } else {
                    toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                }
                commit("setLoadingFinancial", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setLoadingFinancial", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));    
                }
            });
        },
        async getPortfolioPerformanceData({ commit, dispatch }, data){
            if(data === undefined || data.selectedClient === undefined || data.selectedClient.id === undefined) {
                return;
            }
            commit("setSummaryError", false);
            commit("setLoadingSummary", true);
            API.get(
                'getPortfolioPerformance'+(await getSessionToken() === null ? 'Shared' : ''),
                data.selectedClient.id,
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    let results = {};
                    results.data = reply;
                    commit("setPortfolioPerformanceData", results);
                } else {
                    toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                }
                commit("setLoadingSummary", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setLoadingSummary", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit("setSummaryError", true);
                    throw err;    
                }
            });
        },
        async getImpactIndicatorsData({ commit, dispatch }, data){
            if(data === undefined || data.selectedClient === undefined || data.selectedClient.id === undefined) {
                return;
            }
            commit("setImpactError", false);
            commit("setLoadingImpact", true);
            API.get(
                'getImpactIndicators'+(await getSessionToken() === null ? 'Shared' : ''),
                data.selectedClient.id,
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
                .then((reply) => {
                    if (reply && Object.keys(reply).length > 0) {
                        let results = {};
                        results.data = reply;
                        commit("setImpactIndicatorsData", results);
                        commit("setImpactError", false);
                    } else {
                        toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                        commit("setImpactError", true);
                    }
                    commit("setLoadingImpact", false);
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit("setLoadingImpact", false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        commit("setImpactError", true);
                        throw err;    
                    }
                });
        },
        async getImpactIndicatorsSummaryData({ commit, dispatch }, data){
            if(data === undefined || data.selectedClient === undefined || data.selectedClient.id === undefined) {
                return;
            }
            commit("setImpactError", false);
            commit("setLoadingImpact", true);
            API.get(
                'getImpactIndicatorsSummary'+(await getSessionToken() === null ? 'Shared' : '' ),
                `${data.selectedClient.id ? data.selectedClient.id : ''}?limited=${this.getters["profile/getUserCapability"] == 7 ? true : false}&type=${data.type}`,
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
            .then((reply) => {

                if (reply) {
                    let results = {};
                    results = reply;
                    commit("setImpactIndicatorsSummaryData", results);
                    commit("setImpactError", false);
                }
                commit("setLoadingImpact", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setLoadingImpact", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit("setImpactError", true);
                    throw err;    
                }
            });
        },
        async getImpactIndicatorsDetailInitialData({commit, dispatch}, data) {
            commit('setImpactDetailError', false);
            let impactDetailParameters = this.getters["performance/getImpactDetailPortfolioData"];
            commit('setLoadingImpactDetail', true);
            API.get(
                'getImpactIndicatorsDetail'+(await getSessionToken() === null ? 'Shared' : ''),
                `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : impactDetailParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${false}&category=${data.category}&type=${data.type}`,
                {
                  headers: {
                    Authorization: await getSessionToken()
                  }
                }
            ).then(async (reply) => {
                if (reply.page > 1) {
                    impactDetailParameters.companies = [...impactDetailParameters.companies, ...reply.companies];
                } else {
                    impactDetailParameters.companies = reply.companies;
                }
                impactDetailParameters.companies.forEach(company => {
                    company.metrics.forEach(metric => {
                        for (const key in metric) {
                            if (key != "code") {
                                company[metric['code'] + '-' + getFormattedString(key)] = metric[key];
                            }
                        }
                    })
                });
                impactDetailParameters.find = reply.find;
                impactDetailParameters.order = reply.order;
                impactDetailParameters.orderBy = reply.orderBy;
                impactDetailParameters.page = reply.page;
                impactDetailParameters.perPage = reply.perPage;
                impactDetailParameters.total = reply.total;
                commit('setImpactDetailPortfolio', impactDetailParameters);
                commit('setLoadingImpactDetail', false);

                commit('setImpactDetailError', false)
                let impactDetailParametersBenchmark = this.getters["performance/getImpactDetailBenchmarkData"];
                commit('setLoadingImpactDetail', true);
                API.get(
                    'getImpactIndicatorsDetail'+(await getSessionToken() === null ? 'Shared' : ''),
                    `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : impactDetailParametersBenchmark.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${true}&category=${data.category}&type=${data.type}`,
                    {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                    }
                ).then(reply => {
                    if (reply.page > 1) {
                        impactDetailParametersBenchmark.companies = [...impactDetailParametersBenchmark.companies, ...reply.companies];
                    } else {
                        impactDetailParametersBenchmark.companies = reply.companies;
                    }
                    impactDetailParametersBenchmark.companies.forEach(company => {
                        company.metrics.forEach(metric => {
                            for (const key in metric) {
                                if (key != "code") {
                                    company[metric['code'] + '-' + getFormattedString(key)] = metric[key];
                                }
                            }
                        })
                    });
                    impactDetailParametersBenchmark.find = reply.find;
                    impactDetailParametersBenchmark.order = reply.order;
                    impactDetailParametersBenchmark.orderBy = reply.orderBy;
                    impactDetailParametersBenchmark.page = reply.page;
                    impactDetailParametersBenchmark.perPage = reply.perPage;
                    impactDetailParametersBenchmark.total = reply.total;
                    commit('setImpactDetailBenchmark', impactDetailParametersBenchmark);
                    commit('setLoadingImpactDetail', false);
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit('setLoadingImpactDetail', false);
                        if (err.message && err.message.includes('504')) 
                            commit('setImpactDetailError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;    
                    }
                })
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoadingImpactDetail', false);
                    if (err.message && err.message.includes('504')) 
                        commit('setImpactDetailError', true);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        async getImpactIndicatorsDetailData({commit, dispatch}, data) {
            commit('setImpactDetailError', false);
            let impactDetailParameters = data.benchmark ? this.getters["performance/getImpactDetailBenchmarkData"] : this.getters["performance/getImpactDetailPortfolioData"];
            commit('setLoadingImpactDetailResults', true);
            API.get(
                'getImpactIndicatorsDetail'+(await getSessionToken() === null ? 'Shared' : ''),
                `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : impactDetailParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${data.benchmark}&category=${data.category}&type=${data.type}`,
                {
                  headers: {
                    Authorization: await getSessionToken()
                  }
                }
            ).then(reply => {
                if (reply.page > 1) {
                    impactDetailParameters.companies = [...impactDetailParameters.companies, ...reply.companies];
                } else {
                    impactDetailParameters.companies = reply.companies;
                }
                impactDetailParameters.companies.forEach(company => {
                    company.metrics.forEach(metric => {
                        for (const key in metric) {
                            if (key != "code") {
                                company[metric['code'] + '-' + getFormattedString(key)] = metric[key];
                            }
                        }
                    })
                });
                impactDetailParameters.find = reply.find;
                impactDetailParameters.order = reply.order;
                impactDetailParameters.orderBy = reply.orderBy;
                impactDetailParameters.page = reply.page;
                impactDetailParameters.perPage = reply.perPage;
                impactDetailParameters.total = reply.total;
                data.benchmark ? commit('setImpactDetailBenchmark', impactDetailParameters) : commit('setImpactDetailPortfolio', impactDetailParameters);
                commit('setLoadingImpactDetailResults', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoadingImpactDetailResults', false);
                    if (err.message && err.message.includes('504')) 
                        commit('setImpactDetailError', true);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        async getDailyPerformanceDataPDF({commit, dispatch}, data) {
            API.get(
                'getDailyPerformancePDF',
                data.selectedClient.id,
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            ).then((reply) => {
                const returns = {
                    portfolio: {
                        cumulative: {
                            lastWeek: reply.portfolio.total.weekAgoCumulative ? reply.portfolio.total.weekAgoCumulative : 0,
                            lastMonth: reply.portfolio.total.monthAgoCumulative ? reply.portfolio.total.monthAgoCumulative : 0,
                            lastYear: reply.portfolio.total.yearToDateCumulative ? reply.portfolio.total.yearToDateCumulative : 0,
                        },
                        rolling: {
                            lastWeek: reply.portfolio.total.weekAgoRolling ? reply.portfolio.total.weekAgoRolling : 0,
                            lastMonth: reply.portfolio.total.monthAgoRolling ? reply.portfolio.total.monthAgoRolling : 0,
                            lastYear: reply.portfolio.total.yearToDateRolling ? reply.portfolio.total.yearToDateRolling : 0,
                        }
                    },
                    benchmark: {
                        cumulative: {
                            lastWeek: reply.benchmark.total.weekAgoCumulative ? reply.portfolio.total.weekAgoCumulative : 0,
                            lastMonth: reply.benchmark.total.monthAgoCumulative ? reply.portfolio.total.monthAgoCumulative : 0,
                            lastYear: reply.benchmark.total.yearToDateCumulative ? reply.portfolio.total.yearToDateCumulative : 0,
                        },
                        rolling: {
                            lastWeek: reply.benchmark.total.weekAgoCumulative ? reply.portfolio.total.weekAgoRolling : 0,
                            lastMonth: reply.benchmark.total.monthAgoRolling ? reply.portfolio.total.monthAgoRolling : 0,
                            lastYear: reply.benchmark.total.yearToDateRolling ? reply.portfolio.total.yearToDateRolling : 0,
                        }
                    },
                }
                commit('setPdfPerformanceReturns', returns);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;
                }
            });
        },
        resetClientPerformanceData({commit}) {
            commit("setClientPerformanceData", {});
        },
        resetClientPerformancePdfReturns({commit}) {
            commit("setPdfPerformanceReturns", {
                portfolio: {
                    cumulative: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    },
                    rolling: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    }
                },
                benchmark: {
                    cumulative: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    },
                    rolling: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    }
                },
            } );
        },
        clearImpactDetailPortfolio({commit}) {
            let impactParameters = {
                companies: [],
                find: "",
                order: "asc",
                orderBy: "",
                page: 1,
                perPage: 20,
                total: 1
            };
            commit('setImpactDetailPortfolio', impactParameters);
            commit('setImpactDetailError', false);
        },
        clearImpactDetailBenchmark({commit}) {
            let impactParameters = {
                companies: [],
                find: "",
                order: "asc",
                orderBy: "",
                page: 1,
                perPage: 20,
                total: 1
            };
            commit('setImpactDetailBenchmark', impactParameters);
            commit('setImpactDetailError', false);
        },
    },
    getters: {
        getLoadingSummary: (state) => {
            return state.loadingSummary;
        },
        getLoadingFinancial: (state) => {
            return state.loadingFinancial;
        },
        getLoadingImpact: (state) => {
            return state.loadingImpact;
        },
        getDailyPerformanceData: state => {
            return state.clientPerformance.DailyPerformanceData;
        },
        getPortfolioGeneralData: state => {
            return state.clientPerformance.PortfolioGeneralData;
        },
        getPortfolioPerformanceData: state => {
            return state.clientPerformance.PortfolioPerformanceData;
        },
        getImpactIndicatorsData: state => {
            return state.clientPerformance.ImpactIndicatorsData;
        },
        getPdfPerformanceReturns: state => {
            return state.pdfPerformanceReturns;
        },
        getSummaryError: state => {
            return state.summaryError;
        },
        getImpactError: state => {
            return state.impactError;
        },
        getImpactIndicatorsSummaryData: state => {
            return state.impactSummary;
        },
        getImpactDetailPortfolioData: state => {
            return state.impactDetail.portfolio;
        },
        getImpactDetailBenchmarkData: state => {
            return state.impactDetail.benchmark;
        },
        getLoadingImpactDetail: state => {
            return state.loadingImpactDetail;
        },
        getImpactDetailError: state => {
            return state.impactDetailError;
        },
        getLoadingImpactDetailResults: state => {
            return state.loadingImpactDetailResults;
        }
    }
}

export default performance
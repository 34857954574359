<template>
    <div id="socials-section" class="col-12 col-md-4 mt-4 mt-md-0">
        <div class="fnav-sec">
            <h6 class="text-uppercase underliner pb-3">{{$t("content.followUs")}}</h6>
            <a href="https://www.linkedin.com/company/physisinvest/" target="_blank" class="me-3 footer-link"><i class="fab fa-linkedin"></i></a>
            <a href="https://www.facebook.com/physisinvestment/" target="_blank" class="me-3 footer-link"><i class="fab fa-facebook-square"></i></a>
            <a href="https://twitter.com/physisinvest" target="_blank" class="me-3 footer-link"><i class="fab fa-twitter-square"></i></a>
            <a href="https://www.youtube.com/channel/UCwGF6bz2bV4TeN9FxOwbIwA/about" target="_blank" class="me-3 footer-link"><i class="fab fa-youtube-square"></i></a>
        </div>
        <h6 class="text-uppercase underliner mt-3 pb-3">{{$t("content.newsletter")}}</h6>
        <div id="hubspot-newsletter-form">
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        const script = document.createElement("script");
        script.src="https://js.hsforms.net/forms/shell.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "7637513",
                    formId: "00e9bfbd-8633-4197-b586-d96df1219efa",
                    target: "#hubspot-newsletter-form",
                    cssRequired: '.hs-input {border-bottom: 5px solid #fff !important; color: #fff; font-size: 0.8rem !important; padding-left: 0 !important; width: 100% !important;} .hs-input::placeholder {font-size: 0.8rem !important; color: #fff !important; opacity: .8 !important;} .hs-button {position: absolute !important; top: 1.1rem !important; right: 0 !important; padding: 0 !important; background: transparent !important; border: none !important; font-weight: 600 !important; text-transform: uppercase !important; letter-spacing: 1.5 !important;} .hs-error-msgs {position: absolute !important; left: 0 !important; margin-top: 1rem !important; padding-left: 0 !important;} .hs-error-msg, .hs-error-msgs .hs-main-font-element {color: #fff !important; font-size: 0.8rem !important; background: red !important; padding: 4px !important; border-radius: 5px !important;} .hs-error-msgs .hs-main-font-element {margin-top: .5rem !important;} input:-webkit-autofill, input:-webkit-autofill:focus {transition: background-color 600000s 0s, color 600000s 0s !important;} .actions {margin: 0 !important; padding: 0 !important;}'
                });
            }   
        })
    }
}
</script>

<style lang="scss" scoped>

#socials-section {
    .footer-link {
        font-size: 1.5rem !important;
    }
}

</style>
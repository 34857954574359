import {getSessionToken} from '../http/auth'
import {API} from '../http/api'
import router from "../router";
import axios from 'axios';
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();

const portfolioHub = {
    namespaced: true,
    state: {
        clients: [],
        selectedClient: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            id: '',
            imageUrl: null,
            portfolio: {
                investmentAmount: null,
                investmentDay: '',
                paiUpdatedAt: '',
                aum: false,
                updatedAt: null,
                portfolio: {
                    items: [],
                    cash: ''
                },
                benchmark: {
                    items: [],
                    cash: ''
                }
            }
        },
        updatingClient: false,
        holdings: {
            portfolio: {
                data: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "desc",
                find: "",
                orderBy: "value"
            },
            benchmark: {
                data: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "desc",
                find: "",
                orderBy: "value"
            }
        },
        holdingsPdf: {
            portfolio: {
                data: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "desc",
                find: "",
                orderBy: "value"
            },
            benchmark: {
                data: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "desc",
                find: "",
                orderBy: "value"
            }
        },
        loadingHoldings: false,
        portfolio: {
            portfolio: {},
            benchmark: {}
        },
        loadingPortfolio: false,
        clientCreated: false,
        indentifiersList: [],
        clientDeleted: false,
        deletingClient: false,
        loadingClientImg: false,
        PAICalculated: false,
        countriesAllocation: {
            portfolio: [],
            benchmark: []
        },
        runningTest: false,
        testProgress: 0,
        loadingType: 0,
        portfolioError: false,
        holdingsError: false,
        holdingsPortfolioError: false,
        holdingsBenchmarkError: false,
        timeout: false,
        csvURL: null,
        loadingCSVURL: false,
        loadingClients: false,
        estimatedData: false
    },
    mutations: {
        setAdvisorClients(state, payload) {
            state.clients = payload;
        },
        setSelectedClient(state, payload) {
            state.selectedClient = payload;
        },
        setUpdatingClient(state, payload) {
            state.updatingClient= payload;
        },
        mergeSelectedClientData(state, payload) {
            state.selectedClient.firstname = payload.firstname;
            state.selectedClient.lastname = payload.lastname;
            state.selectedClient.email = payload.email;
            state.selectedClient.phone = payload.phone;
            let clientToUpdateIndex = state.clients.findIndex(client => client.id === state.selectedClient.id);
            if (clientToUpdateIndex >= 0) {
                state.clients[clientToUpdateIndex] = state.selectedClient;
            }
        },
        setBenchmarkToggle(state, payload) {
            if (!payload) {
                state.selectedClient
            }
        },
        mergeSelectedClientPortfolio(state, payload) {
            state.selectedClient.portfolio = payload;
        },
        setHoldingsPortfolioData(state, payload) {
            state.holdings.portfolio = payload;
        },
        setHoldingsBenchmarkData(state, payload) {
            state.holdings.benchmark = payload;
        },
        setHoldingsPdfPortfolioData(state, payload) {
            state.holdingsPdf.portfolio = payload;
        },
        setHoldingsPdfBenchmarkData(state, payload) {
            state.holdingsPdf.benchmark = payload;
        },
        setLoadingHoldings(state, payload) {
            state.loadingHoldings = payload;
        },
        setPortfolioData(state, payload) {
            state.portfolio = payload;
        },
        setLoadingPortfolio(state, payload) {
            state.loadingPortfolio = payload;
        },
        addClient(state,payload) {
            state.clients.unshift(payload);
        },
        setClientCreated(state, payload) {
            state.clientCreated = payload;
        },
        setIdentifiers(state, payload) {
            state.indentifiersList = payload;
        },
        setClientDeleted(state, payload) {
            state.clientDeleted = payload;
        },
        setDeletingClient(state, payload) {
            state.deletingClient = payload
        },
        deleteClient(state, payload) {
            let foundIdx = state.clients.findIndex(client => client.id === payload);
            if (foundIdx > -1) {
                state.clients.splice(foundIdx, 1);
            }
        },
        setClientImg(state, payload) {
            state.selectedClient.imageUrl = payload;
        },
        setLoadingClientImg(state, payload) {
            state.loadingClientImg = payload;
        },
        setPAICalculated(state, payload) {
            state.PAICalculated = payload
        },
        setCountriesAllocation(state, payload) {
            state.countriesAllocation = payload;
        },
        setClientPortfolioUpdateAt(state, payload) {
            state.selectedClient.portfolio.updatedAt = payload;
        },
        resetClientPortfolio(state, payload) {
            state.portfolio = payload;
        },
        setRunningTest(state, payload) {
            state.runningTest = payload
        },
        setTestProgress(state, payload) {
            state.testProgress = payload;
        },
        setLoadingType(state, payload) {
            state.loadingType = payload;
        },
        setPortfolioError(state, payload) {
            state.portfolioError = payload
        },
        setHoldingsPortfolioError(state, payload) {
            state.holdingsPortfolioError = payload
        },
        setHoldingsBenchmarkError(state, payload) {
            state.holdingsBenchmarkError = payload
        },
        setTimeout(state, payload) {
            state.timeout = payload;
        },
        setCSVURL(state, payload) {
            state.csvURL = payload;
        },
        setLoadingCSVURL(state, payload) {
            state.loadingCSVURL = payload;
        },
        setLoadingClients(state, payload) {
            state.loadingClients = payload;
        },
        setLastUpdatedPAI(state, payload) {
            state.selectedClient.portfolio.paiUpdatedAt = payload;
        },
        setEstimatedData(state, payload) {
            state.estimatedData = payload;
        }
    },
    actions: {
        async getAdvisorClients({commit, dispatch}) {
            commit('setLoadingClients', true);
            API.get(
                'clientV2',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(data => {
                const clients = data.map(v => ({
                    id: v.id,
                    firstname: v.firstname ? v.firstname : '',
                    lastname: v.lastname ? v.lastname : '',
                    email: v.email ? v.email : '',
                    phone: v.phone ? v.phone : '',
                    imageUrl: v.imageUrl === null ? undefined : `${v.imageUrl}`,
                    portfolio: {
                        investmentDay: v.portfolio.investmentDay ? new Date(v.portfolio.investmentDay).toISOString().slice(0, -1) : '',
                        investmentAmount: v.portfolio.investmentAmount ? v.portfolio.investmentAmount : null,
                        paiUpdatedAt: v.portfolio.paiUpdatedAt ? v.portfolio.paiUpdatedAt : null,
                        updatedAt: v.portfolio.updatedAt ? v.portfolio.updatedAt : null,
                        isOutdated: v.portfolio.isOutdated,
                        aum: false,
                        portfolio: {
                            items: v.portfolio.portfolio ? v.portfolio.portfolio.items.map(item => ({
                                input: item.input,
                                fundId: item.fundId,
                                holdingId: item.holdingId,
                                weight: item.weight,
                                checked: true,
                            })) : [],
                            cash: v.portfolio.portfolio.cash ? v.portfolio.portfolio.cash : 0,
                        },
                        benchmark: {
                            items: v.portfolio.benchmark ? v.portfolio.benchmark.items.map(item => ({
                                input: item.input,
                                fundId: item.fundId,
                                holdingId: item.holdingId,
                                weight: item.weight,
                                checked: true,
                            })) : [],
                            cash: v.portfolio.benchmark.cash ? v.portfolio.benchmark.cash : 0,
                        }
                    }
                }));
                commit('setAdvisorClients', clients);
                commit('setLoadingClients', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setLoadingClients', false);
                    throw err;    
                }
            })
        },
        async getHoldingsInitial({commit, dispatch}, data) {
            let holdingsParameters = this.getters["portfolioHub/getHoldingsData"].portfolio;
            commit('setHoldingsPortfolioError', false);
            commit('setLoadingHoldings', true);
            let isLimited = this.getters['profile/getUserCapability'] == 7;
            API.get(
                'fetchPaginatedHoldingsV2'+(await getSessionToken() === null ? 'Shared' : ''),
                `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : holdingsParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${false}&limited=${isLimited}`,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(async reply => {
                let holdings = reply;
                if (data.page > 1) {
                    holdings.portfolio.data = [...holdingsParameters.data, ...holdings.data];
                }
                if (data.flag && data.flag == 'pdf') {
                    commit('setHoldingsPdfPortfolioData', holdings);
                }
                else {
                    commit('setHoldingsPortfolioData', holdings);
                }
                if (data.benchmark) {
                    let holdingsParametersBenchmark = this.getters["portfolioHub/getHoldingsData"].benchmark;
                    API.get(
                        'fetchPaginatedHoldingsV2'+(await getSessionToken() === null ? 'Shared' : ''),
                        `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : holdingsParametersBenchmark.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${true}&limited=${isLimited}`,
                        {
                            headers: {
                                Authorization: await getSessionToken()
                            }
                        }
                    ).then(reply => {
                        let holdings = reply;
                        if (data.page > 1) {
                            holdings.benchmark.data = [...holdingsParametersBenchmark.data, ...holdings.data];
                        }
                        if (data.flag && data.flag == 'pdf') {
                            commit('setHoldingsPdfBenchmarkData', holdings);
                        }
                        else {
                            commit('setHoldingsBenchmarkData', holdings);
                        }
                        commit('setLoadingHoldings', false);
                    }).catch(err => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            commit('setHoldingsBenchmarkError', false);
                            commit('setLoadingHoldings', false);
                            throw err;            
                        }
                    })
                }
                else
                    commit('setLoadingHoldings', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setHoldingsPortfolioError', false);
                    commit('setLoadingHoldings', false);
                    throw err;                
                }
            })
        },
        async getHoldings({commit, dispatch}, data) {
            let holdingsParameters = data.benchmark ? this.getters["portfolioHub/getHoldingsData"].benchmark : this.getters["portfolioHub/getHoldingsData"].portfolio;
            data.benchmark ? commit('setHoldingsPortfolioError', false) : commit('setHoldingsBenchmarkError', false);
            commit('setLoadingHoldings', true);
            let isLimited = this.getters['profile/getUserCapability'] == 7;
            API.get(
                'fetchPaginatedHoldingsV2'+(await getSessionToken() === null ? 'Shared' : ''),
                `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : holdingsParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${data.benchmark}&limited=${isLimited}`,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(reply => {
                let holdings = reply;
                if (data.page > 1) {
                    data.benchmark ? holdings.data = [...holdingsParameters.data, ...holdings.data] : holdings.data = [...holdingsParameters.data, ...holdings.data];
                }
                if (data.flag && data.flag == 'pdf') {
                    data.benchmark ? commit('setHoldingsPdfBenchmarkData', holdings) : commit('setHoldingsPdfPortfolioData', holdings);
                }
                else {
                    data.benchmark ? commit('setHoldingsBenchmarkData', holdings) : commit('setHoldingsPortfolioData', holdings);
                }
                commit('setLoadingHoldings', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    data.benchmark ? commit('setHoldingsPortfolioError', false) : commit('setHoldingsBenchmarkError', false);
                    commit('setLoadingHoldings', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;                
                }
            })
        },
        async getPortfolio({commit, dispatch}, id) {
            commit('setPortfolioError', false);
            commit('setLoadingPortfolio', true);
            API.get(
                'getPortfolioByClientV2'+(await getSessionToken() === null ? 'Shared' : ''),
                id ? id : '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(data => {
                commit('setPortfolioData', data);
                commit('setLoadingPortfolio', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoadingPortfolio', false);
                    commit('setPortfolioError', true);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        catchError({commit}, err) {
            if (err.message && err.message.includes('504')) 
                commit('setTimeout', true);
            commit('setRunningTest', false);
            throw err;
        },
        catchUpdatingError({commit, dispatch}, err) {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                if (err.message && err.message.includes('504')) 
                    commit('setTimeout', true);
                commit('setUpdatingClient', false);
                commit('setRunningTest', false);
                throw err;
            }
        },
        async selectClient({commit, dispatch}, client) {
            commit('setTestProgress', 0);
            commit('setPAICalculated', false);
            dispatch('pai/resetPAIProgress', 0, {root: true});
            commit('setSelectedClient', client);
            if (client.portfolio.isOutdated) {
                commit('setLoadingType', 2);
                commit('setRunningTest', true);
                commit('setTimeout', false);
                API.get(
                    'providerRetrieveV2',
                    client.id,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }).then(async () => {
                    commit('setTestProgress', 20);
                    API.post(
                        'recalculatePortfolioV2',
                        client.id,
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }).then(async () => {
                        commit('setTestProgress', 40);
                        let updatedDate = new Date().toISOString().slice(0, -1);
                        commit('setClientPortfolioUpdateAt', updatedDate);
                            API.post(
                                'getEuTaxonomy',
                                client.id, {
                                    headers: {
                                        Authorization: await getSessionToken()
                                    }
                                }
                            ).then(async () => {
                                commit('setTestProgress', 70);
                                API.post(
                                    'calculateImpactV2',
                                    `${client.id}?type=0`,
                                    {
                                        headers: {
                                            Authorization: await getSessionToken()
                                        },
                                        body: {
                                            indicators: this.getters['customization/getMappedDefaultIndicators']
                                        }
                                    }
                                ).then(async () => {
                                    commit('setTestProgress', 80);
                                    API.post(
                                        'calculateImpactV2',
                                        `${client.id}?type=2`,
                                        {
                                            headers: {
                                                Authorization: await getSessionToken()
                                            },
                                            body: {
                                                indicators: this.getters['customization/getMappedDefaultIndicators']
                                            }
                                        }
                                        ).then(() => {
                                            let updatedDate = new Date().toISOString().slice(0, -1);
                                            commit('setClientPortfolioUpdateAt', updatedDate);        
                                            commit('setTestProgress', 100);
                                            commit('setRunningTest', false);
                                            commit('setTestProgress', 0);
                                        }).catch((err) => {
                                            dispatch('catchUpdatingError', err);
                                        })
                                }).catch((err) => {
                                    dispatch('catchUpdatingError', err);
                                })
                            }).catch((err) => {
                                dispatch('catchError', err);
                            })
                    }).catch((err) => {
                        if (err.message && err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setRunningTest', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    });
                }).catch((err) => {
                    if (err.message && err.message.includes('504')) 
                        commit('setTimeout', true);
                    commit('setRunningTest', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;
                });
            }
            if (!this.getters['portfolioHub/getPAIOutdated']) {
                dispatch('setPAICalculated', true);
            }
        },
        async updateClientData({commit, state, dispatch}, data) {
            commit('setUpdatingClient', true);
            API.patch(
                'clientV2',
                state.selectedClient.id,//client ID
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then(() => {
                commit('setUpdatingClient', false);
                commit('mergeSelectedClientData', data);
                toast.success(i18n.global.t('alert.success.changesSaved'));
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setUpdatingClient', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    console.log(err)    
                }
            })
        },
        async updateClientPortfolio({commit, dispatch, state}, data) {
            commit('setUpdatingClient', true);
            commit('setPAICalculated', false);
            commit('setTestProgress', 0);
            dispatch('pai/resetPAIProgress', 0, {root: true});
            commit('setLoadingType', 2);
            commit('setRunningTest', true);
            commit('setTimeout', false);                                                                                        
            API.post(
                'portfolioV2',
                '',//client ID
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then( async () => {
                commit('setTestProgress', 20);
                commit('mergeSelectedClientPortfolio', data);
                commit('setUpdatingClient', false);
                toast.success(i18n.global.t('alert.success.changesSaved'));
                API.get(
                    'providerRetrieveV2',
                    state.selectedClient.id,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }).then(async () => {
                    commit('setTestProgress', 40);
                    API.post(
                        'recalculatePortfolioV2',
                        state.selectedClient.id,
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }).then(async () => {
                        commit('setTestProgress', 60);
                        API.post(
                            'getEuTaxonomy',
                            state.selectedClient.id,
                            {
                                headers: {
                                    Authorization: await getSessionToken()
                                }
                            }
                        ).then( async () => {
                            commit('setTestProgress', 70);
                            API.post(
                                'calculateImpactV2',
                                `${state.selectedClient.id}?type=0`,

                                {
                                    headers: {
                                        Authorization: await getSessionToken()
                                    },
                                    body: {
                                        indicators: this.getters['customization/getMappedDefaultIndicators']
                                    }
                                }
                            ).then(async () => {
                                commit('setTestProgress', 80);
                                API.post(
                                    'calculateImpactV2',
                                    `${state.selectedClient.id}?type=2`,
                                    {
                                        headers: {
                                            Authorization: await getSessionToken()
                                        },
                                        body: {
                                            indicators: this.getters['customization/getMappedDefaultIndicators']
                                        }
                                    }
                                    ).then(() => {
                                        let updatedDate = new Date().toISOString().slice(0, -1);
                                        commit('setClientPortfolioUpdateAt', updatedDate);        
                                        commit('setTestProgress', 100);
                                        commit('setRunningTest', false);
                                        commit('setTestProgress', 0);
                                    }).catch((err) => {
                                        dispatch('catchUpdatingError', err);
                                    })
                            }).catch((err) => {
                                dispatch('catchUpdatingError', err);
                            })
                        }).catch((err) => {
                            dispatch('catchUpdatingError', err);
                        });
                    }).catch((err) => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            if (err.message && err.message.includes('504')) 
                                commit('setTimeout', true);
                            commit('setUpdatingClient', false);
                            commit('setRunningTest', false);
                            toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                            throw err;
                        }
                    });
                }).catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message && err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setUpdatingClient', false);
                        commit('setRunningTest', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    if (err.message && err.message.includes('504')) 
                        commit('setTimeout', true);
                    commit('setUpdatingClient', false);
                    commit('setRunningTest', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;
                }
            })
        },
        async addClient({commit, dispatch}, data) {
            commit('setUpdatingClient', true);
            commit('setLoadingType', 0);
            commit('setTestProgress', 0);
            dispatch('pai/resetPAIProgress', 0, {root: true});
            commit('setRunningTest', true);
            commit('setTimeout', false);
            API.post(
                'clientV2',
                '',//client ID
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then( async (reply) => {
                commit('setTestProgress', 10);
                let newClient = {
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                    id: '',
                    portfolio: {
                        investmentAmount: null,
                        investmentDay: '',
                        paiUpdatedAt: '',
                        portfolio: {
                            items: [],
                            cash: ''
                        },
                        benchmark: {
                            items: [],
                            cash: ''
                        }
                    }
                }
                let clientToAdd = {
                    ...newClient,
                    ...reply
                }
                clientToAdd.portfolio.updatedAt = reply.updatedAt;
                commit('addClient', clientToAdd);
                commit('setClientCreated', true);
                commit('setUpdatingClient', false);
                commit('setSelectedClient', clientToAdd)
                toast.success(i18n.global.t('alert.success.cliendCreated'));
                setTimeout(() => {
                    router.push({
                        name: 'Client',
                        params: {id: clientToAdd.id}
                    })
                }, 250);
                API.get(
                    'providerRetrieveV2',
                    reply.id,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }).then(async () => {
                    commit('setTestProgress', 30);
                    API.post(
                        'recalculatePortfolioV2',
                        reply.id,
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }).then(async () => {
                        commit('setTestProgress', 60);
                        API.post(
                            'getEuTaxonomy',
                            reply.id,
                            {
                                headers: {
                                    Authorization: await getSessionToken()
                                }
                            }
                        ).then( async () => {
                            commit('setTestProgress', 70);
                            API.post(
                                'calculateImpactV2',
                                `${reply.id}?type=0`,

                                {
                                    headers: {
                                        Authorization: await getSessionToken()
                                    },
                                    body: {
                                        indicators: this.getters['customization/getMappedDefaultIndicators']
                                    }
                                }
                            ).then(async () => {
                                commit('setTestProgress', 80);
                                API.post(
                                    'calculateImpactV2',
                                    `${reply.id}?type=2`,
                                    {
                                        headers: {
                                            Authorization: await getSessionToken()
                                        },
                                        body: {
                                            indicators: this.getters['customization/getMappedDefaultIndicators']
                                        }
                                    }
                                    ).then(() => {
                                        let updatedDate = new Date().toISOString().slice(0, -1);
                                        commit('setClientPortfolioUpdateAt', updatedDate);        
                                        commit('setTestProgress', 100);
                                        commit('setRunningTest', false);
                                        commit('setTestProgress', 0);
                                    }).catch((err) => {
                                        dispatch('catchUpdatingError', err);
                                    })
                            }).catch((err) => {
                                dispatch('catchUpdatingError', err);
                            })
                        }).catch((err) => {
                            dispatch('catchUpdatingError', err);
                        });
                    }).catch((err) => {
                        dispatch('catchUpdatingError', err);
                    });
                }).catch((err) => {
                    dispatch('catchUpdatingError', err);
                });
            }).catch(err => {
                dispatch('catchUpdatingError', err);
            });
        },
        async deleteClient({commit, dispatch}, id) {
            commit('setDeletingClient', true);
            API.del(
                'deleteAdvisorClient',
                id,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(() => {
                commit('setDeletingClient', false);
                commit('deleteClient', id);
                commit('setClientDeleted', true);
                toast.success(i18n.global.t('alert.success.clientDeleted'));
                setTimeout(() => {
                    router.push({
                        name: 'Clients',
                    })
                    commit('setClientDeleted', false);
                }, 250);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setUpdatingClient', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setDeletingClient', false);
                    console.log(err)    
                }
            })
        },
        async uploadClientImg({commit, dispatch}, data) {
            commit('setLoadingClientImg', true);
            API.post(
                'setClientImg',
                data.userId,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {type: data.file.type.split('/')[1]}
                }
            ).then(async (reply) => {
                axios.put(reply.url, data.base64Img)
                    .then(() => {
                        commit('setClientImg', reply.imageUrl);
                        commit('setLoadingClientImg', false);
                    }).catch((err) => {
                        commit('setLoadingClientImg', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                });
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoadingClientImg', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    console.log(err)    
                }
            })
        },
        async getPortfolioCountriesAllocation({commit, dispatch}, id) {
            commit('setLoadingPortfolio', true);
            API.get(
                'getCountryPortfolioAllocation',
                id ? id : '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(data => {
                commit('setCountriesAllocation', data)
                commit('setLoadingPortfolio', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    console.log(err)
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));    
                }
            })
        },
        toggleBenchmark({commit}, data) {
            commit('setBenchmarkToggle', data);
        },
        setIdentifiers({commit}, data) {
            commit('setIdentifiers', data);
        },
        resetClientPortfolio({commit}) {
            commit("resetClientPortfolio", {
                portfolio: {},
                benchmark: {}
            },)
            commit('setPortfolioError', false);
            commit('setHoldingsPortfolioError', false);
        },
        setTimeout({commit}, data) {
            commit('setTimeout', data);
        },
        async getCSV({commit, dispatch}, data) {
            commit('setLoadingCSVURL', true);
            API.get(
                `get${data.section}CSVv2`+(await getSessionToken() === null ? 'Shared' : ''),
                data.section === 'Impact' ? `${data.id ? data.id : ''}?type=${data.type}` : `${data.id ? data.id : ''}`,
                {
                    headers: {
                    Authorization: await getSessionToken()
                    }
                }
            ).then(data => {
                commit('setCSVURL', data.urlDownload);
                commit('setLoadingCSVURL', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.errorCSV'));
                    commit('setLoadingCSVURL', false);
                    throw err;    
                }
            })
        },
        clearCSVURL({commit}) {
            commit('setCSVURL', null);
        },
        setPAICalculated({commit}, data) {
            commit('setPAICalculated', data);
        },
        setLastUpdatedPAI({commit}, data) {
            commit('setLastUpdatedPAI', data);
        },
        setEstimatedData({commit}, data) {
            commit('setEstimatedData', data);
        }
    },
    getters: {
        getAdvisorClients: state => {
            return state.clients;
        },
        getAdvisorClientsLength: state => {
            return state.clients.length;
        },
        getSelectedClient: state => {
            return state.selectedClient;
        },
        getUpdatingClient: state => {
            return state.updatingClient;
        },
        getHoldingsData: state => {
            return state.holdings;
        },
        getHoldingsPdfPortfolioData: state => {
            return state.holdingsPdf.portfolio;
        },
        getHoldingsPdfBenchmarkData: state => {
            return state.holdingsPdf.benchmark;
        },
        getLoadingHoldings: state => {
            return state.loadingHoldings;
        },
        getPortfolioData: state => {
            return state.portfolio;
        },
        getLoadingPortfolio: state => {
            return state.loadingPortfolio;
        },
        getPortfolioSummary: state => {
            let data = {};
            data.portfolio = {distribution: state.portfolio.portfolio.distribution, summary: state.portfolio.portfolio.summary};
            data.benchmark = {distribution: state.portfolio.benchmark.distribution, summary: state.portfolio.benchmark.summary};
            return data;
        },
        getBenchmarkDistribution: state => {
            let data = {};
            data.benchmark = {distribution: state.portfolio.benchmark.distribution};
            return data;
        },
        getPortfolioAssets: state => {
            let data = {};
            data.portfolio = state.portfolio.portfolio.assets;
            data.benchmark = state.portfolio.benchmark.assets;
            return data;
        },
        getPortfolioCountries: state => {
            let data = {};
            data.portfolio = state.portfolio.portfolio.countries;
            data.benchmark = state.portfolio.benchmark.countries;
            return data;
        },
        getPortfolioCurrency: state => {
            let data = {};
            data.portfolio = state.portfolio.portfolio.currencies;
            data.benchmark = state.portfolio.benchmark.currencies;
            return data;
        },
        getPortfolioSectors: state => {
            let data = {};
            data.portfolio = state.portfolio.portfolio.sectors;
            data.benchmark = state.portfolio.benchmark.sectors;
            return data;
        },
        getPortfolioSDGs: state => {
            let data = {};
            data.portfolio = state.portfolio.portfolio.goalsDistribution;
            data.benchmark = state.portfolio.benchmark.goalsDistribution;
            return data;
        },
        getClientCreated: state => {
            return state.clientCreated;
        },
        getIdentifiers: state => {
            return state.indentifiersList;
        },
        getClientDeleted: state => {
            return state.clientDeleted;
        },
        getDeletingClient: state => {
            return state.getDeletingClient;
        },
        getLoadingClientImg: state => {
            return state.loadingClientImg;
        },
        getPAICalculated: state => {
            return state.PAICalculated;
        },
        getPortfolioDistribution: state => {
            let data = {};
            let distribution = state.portfolio.portfolio.distribution ? JSON.parse(JSON.stringify(state.portfolio.portfolio.distribution)) : [];
            let distributionB = state.portfolio.benchmark.distribution ? JSON.parse(JSON.stringify(state.portfolio.benchmark.distribution)) : [];
            distribution.sort((a, b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            distributionB.sort((a, b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (distribution.length >= 5) {
                distribution = distribution.splice(0, 5);
            }
            distribution.forEach(fund => {
                fund.weight = fund.weight + '%';
            })
            distributionB.forEach(fund => {
                fund.weight = fund.weight + '%';
            })
            if (distributionB.length >= 5) {
                distributionB = distributionB.splice(0, 5);
            }
            data.portfolio = {distribution: distribution ? distribution : []};
            data.benchmark = {distribution: distributionB ? distributionB : []};
            return data;
        },
        getPortfolioAssetsPdf: state => {
            let data = {};
            let assets = state.portfolio.portfolio.assets ? JSON.parse(JSON.stringify(state.portfolio.portfolio.assets)) : [];
            let assetsB = state.portfolio.benchmark.assets ? JSON.parse(JSON.stringify(state.portfolio.benchmark.assets)) : [];
            assets.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            assetsB.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            if (assets.length >= 10) {
                assets = assets.splice(0, 10);
            }
            if (assetsB.length >= 10) {
                assetsB = assetsB.splice(0, 10);
            }
            data.portfolio = {assets: assets};
            data.benchmark = {assets: assetsB};
            return data;
        },
        getPortfolioCurrenciesPdf: state => {
            let data = {};
            let currencies = state.portfolio.portfolio.currencies ? JSON.parse(JSON.stringify(state.portfolio.portfolio.currencies)) : [];
            let currenciesB = state.portfolio.benchmark.currencies ? JSON.parse(JSON.stringify(state.portfolio.benchmark.currencies)) : [];
            currencies.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            currenciesB.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            if (currencies.length >= 5) {
                currencies = currencies.splice(0, 5);
            }
            if (currenciesB.length >= 5) {
                currenciesB = currenciesB.splice(0, 5);
            }
            data.portfolio = {currencies: currencies};
            data.benchmark = {currencies: currenciesB};
            return data;
        },
        getPortfolioSectorsPdf: state => {
            let data = {};
            let sectors = state.portfolio.portfolio.sectors ? JSON.parse(JSON.stringify(state.portfolio.portfolio.sectors)) : [];
            let sectorsB = state.portfolio.benchmark.sectors ? JSON.parse(JSON.stringify(state.portfolio.benchmark.sectors)) : [];
            sectors.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            sectorsB.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            if (sectors.length >= 5) {
                sectors = sectors.splice(0, 5);
            }
            if (sectorsB.length >= 5) {
                sectorsB = sectorsB.splice(0, 5);
            }
            data.portfolio = {sectors: sectors};
            data.benchmark = {sectors: sectorsB};
            return data;
        },
        getCountriesAllocation: state => {
            let countries = JSON.parse(JSON.stringify(state.countriesAllocation));
            countries.portfolio.sort((a,b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (countries.portfolio.length >= 5) {
                countries.portfolio = countries.portfolio.splice(0, 5);
            }
            countries.portfolio.forEach(country => {
                country.weight = country.weight +'%'
            });
            countries.benchmark.sort((a,b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (countries.benchmark.length >= 5) {
                countries.benchmark = countries.benchmark.splice(0, 5);
            }
            countries.benchmark.forEach(country => {
                country.weight = country.weight +'%'
            });
            return countries;
        },
        getClientPortfolioUpdateAt: state => {
            return state.selectedClient.portfolio.updatedAt;
        },
        getRunningTest: (state) => {
            return state.runningTest;
        },
        getTestProgress: state => {
            return state.testProgress;
        },
        getLoadingType: state => {
            return state.loadingType;
        },
        getPortfolioError: state => {
            return state.portfolioError;
        },
        getHoldingsPortfolioError: state => {
            return state.holdingsPortfolioError;
        },
        getHoldingsBenchmarkError: state => {
            return state.holdingsBenchmarkError;
        },
        getPortfolioHasBenchmark: state => {
            return state.selectedClient.portfolio?.benchmark && state.selectedClient.portfolio?.benchmark?.items?.length > 0; 
        },
        getTimeout: state => {
            return state.timeout;
        },
        getCSVURL: state => {
            return state.csvURL;
        },
        getLoadingCSVURL: state => {
            return state.loadingCSVURL;
        },
        getPortfolioDayOfFirstInvestment: state => {
            return state.selectedClient.portfolio?.investmentDay
        },
        getLoadingClients: state => {
            return state.loadingClients;
        },
        getPAIUpdatedAt: state => {
            return state.selectedClient.portfolio?.paiUpdatedAt;
        },
        getPAIOutdated: state => {
            let today = new Date().getTime();
            let portfolioLastUpdated = new Date(state.selectedClient.portfolio?.updatedAt).getTime();
            let paiUpdatedAt = new Date(state.selectedClient.portfolio?.paiUpdatedAt).getTime();
            let daysInBetween = paiUpdatedAt ? Number(((today - paiUpdatedAt)/(1000*3600*24)).toFixed(0)) : 0;

            return daysInBetween > 31 || (portfolioLastUpdated > paiUpdatedAt);
        },
        getEstimatedData: state => {
            return state.estimatedData;
        }
    }
}

export default portfolioHub
<template>
  <div class="collapse navbar-collapse text-center text-md-left justify-content-between position-relative w-100" id="navbar-menu">
<!--     <ul v-if="impactShow!==false" class="navbar-nav align-items-md-center my-4 my-lg-0 pb-lg-0">

      <li class="nav-item me-0 me-md-3">
        <a class="nav-link" href="https://physisinvestment.com/institutions">{{$t("content.institutions")}}</a>
      </li>
      <li class="nav-item me-0 me-md-3">
        <a class="nav-link" href="https://physisinvestment.com/advisors">{{$t("content.advisors")}}</a>
      </li>
      <li class="nav-item me-0 me-md-3">
        <a class="nav-link" href="https://physisinvestment.com/impactChat">{{$t("content.impactChat")}}</a>
      </li>
      <li class="nav-item me-0 me-md-3">
        <a class="nav-link" href="https://insights.physisinvestment.com">#Physis Insights</a>
      </li>

      <li class="nav-item me-0 me-md-3">
        <a class="nav-link" href="https://physisinvestment.com/about">{{$t("content.about")}}</a>
      </li>
      <DropdownMenu class="d-flex flex-column w-100 p-2 d-lg-none" :mode="'collapse'" :userCapability="userCapability" fontType="montserrat" :isIconMenu="true" @actionEmitted="execAction($event)" :navItem="HelpNav">
        <template #trigger>
            <i class="fas icon fa-question-circle"></i><span class="montserrat-bold ms-1">{{$t("content.help")}}</span>
        </template>
      </DropdownMenu>
    </ul> -->
    <ul class="navbar-nav ms-auto align-items-md-center pb-4 pb-lg-0">
      <li class="nav-item me-0 me-md-3" v-if="!isUserLogged">
        <router-link to="/login" class="nav-link font-yellow">{{$t("content.login")}}</router-link>
      </li>
      <li id="menu-items" class=" d-lg-flex nav-item justify-content-center align-items-center pe-4 profile-menu row-mobile" v-else>
        <DropdownMenu class="me-4" mode="header-dropdown" fontType="montserrat" :userCapability="userCapability" :isIconMenu="true" @actionEmitted="execAction($event)" :navItem="HelpNav">
            <template #trigger>
                <i class="fas icon fa-question-circle" style="color: #00A0FF;"></i><span class="montserrat-bold ms-1" style="color: #00A0FF;">{{$t("content.help")}}</span>
            </template>
        </DropdownMenu>
        <DropdownMenu mode="header-dropdown" :userCapability="userCapability" fontType="montserrat" :isIconMenu="true" @actionEmitted="execAction($event)" :navItem="profileNav">
            <template #trigger>
                <i class="fas icon fa-user" style="color: #00A0FF;"></i>
            </template>
        </DropdownMenu>
      </li>
      <li class="d-none d-lg-flex nav-item justify-content-center language-picker ms-4">
        <div class="d-flex justify-content-between px-0">
          <div id="IT-M" class="it me-2 flag-container" :class="[selectedLang === 'it'? 'selected' :'']" @click="SetLocale('it')">
           <span class="flag h-100"><img class="flag-img" src="@/assets/images/it.webp" onerror="this.src='@/assets/images/it.png'" alt="Italian"></span>
          </div>
          <div class="vl"></div>
          <div id="US-M" class="us ms-2 flag-container" :class="[selectedLang === 'en'? 'selected' :'']" @click="SetLocale('en')">
            <span class="flag h-100"><img class="flag-img" src="@/assets/images/us.webp" onerror="this.src='@/assets/images/us.png'" alt="English"></span>
          </div>
        </div>
      </li>
      <li class="nav-item upper-header-mobile justify-content-center mt-2 mt-md-0 mb-2 me-md-3">
        <div class="d-flex justify-content-between px-0">
          <div id="IT-M" class="it flag-container" :class="[selectedLang === 'it'? 'selected' :'']" @click="SetLocale('it')">
            <span class="title">IT</span><span class="flag h-100"><img class="flag-img" src="@/assets/images/it.webp" onerror="this.src='@/assets/images/it.png'" alt="Italian"></span>
          </div>
          <div class="vl"></div>
          <div id="US-M" class="us mr-0 mr-md-2 flag-container" :class="[selectedLang === 'en'? 'selected' :'']" @click="SetLocale('en')">
            <span class="title">EN</span><span class="flag h-100"><img class="flag-img" src="@/assets/images/us.webp" onerror="this.src='@/assets/images/us.png'" alt="English"></span>
          </div>
        </div>
      </li>
    </ul>
    <div class="d-flex flex-row flex-wrap h-100 position-relative">
    <div class="mobile-bar w-100 card py-4" >
<UserSideMenu></UserSideMenu>
</div></div>

  </div>
</template>

<script>
import {signOut} from '../../../http/auth';
import DropdownMenu from "@/components/platform/general/DropdownMenu.vue";
import UserSideMenu from '../../platform/UserSideMenu.vue';
export default {
  data() {
    return {
      lang: undefined,
      platformToggle: false,
      profileNav: {
        children: [
            {
                name: 'content.accountInfo',
                url: {name: 'Profile'},
                requiredCapability: 0
            },
/*             {
                name: 'content.billing',
                url: {name: 'billing'},
                requiredCapability: 0
            }, */
            {
                name: 'Plan',
                url: {name: 'Plan'},
                requiredCapability: 0
            },
            {
                name: 'logout',
                action: 'logout',
                requiredCapability: 0
            }
        ]
      },
      HelpNav: {
        children: [
            {
                name: 'content.contactSupport',
                action: 'contactSupport',
                requiredCapability: 0
            },
            {
                name: 'content.methodologies',
                url: {name: 'Methodologies'},
                requiredCapability: 0
            },
            {
                name: 'content.videos',
                url: {name: 'Videos'},
                requiredCapability: 0
            },
            {
                name: 'Indicators',
                url: {name: 'Indicators'},
                requiredCapability: 0
            },            {
                name: 'S&P 500',
                action: 'sp500',
                requiredCapability: 0
            },
            {
                name: 'content.FAQ',
                action: 'goToFAQ',
                requiredCapability: 0
            }
            
        ]
      },
      supportURL: "https://physisinvestment.atlassian.net/servicedesk/customer/portal/1",
      faqUrl: "https://physisinvestment.com/faq"
    }
  },
  methods: {
    logout() {
      signOut()
          .then(() => {
            sessionStorage.removeItem('access_level');
            this.$store.dispatch('profile/logout');
            this.$router.push('/login');
          });
    },
    SetLocale(locale) {
      document.cookie = "lang=" + locale;
      this.$i18n.locale = locale;
    },
    execAction(action) {
        this[action]();
    },
    contactSupport(){
        window.open(this.supportURL, '_blank').focus();
    },
    sp500(){
      window.open('https://www.slickcharts.com/sp500', '_blank').focus();
    },
    goToFAQ() {
        window.open(this.faqUrl, '_blank').focus();
    }
  },

  computed: {
    impactShow(){
      if(this.$route.path === '/platform/chat' ||  this.$route.path ===  '/platform/profile/billing' || this.$route.path ===  '/platform/profile/Plan' || this.$route.path ===  '/platform/profile/account_info' ){
        return false
      }else{
        return true
      }
    },
    firstName() {
      let firstName = this.$store.getters['profile/getUserFirstName'];
      return firstName;
    },
    isUserLogged() {
      if(this.$store.getters.getSharing){
        return false
      }
      let userData = this.$store.getters['profile/getUserData'];
      if (userData && userData.CognitoId) {
        return true
      }
      else {
        return false
      }
    },
    selectedLang() {
      return this.$i18n.locale
    },
    userCapability() {
        return this.$store.getters['profile/getUserCapability'];
    }
  },
  components: {
    DropdownMenu,
    UserSideMenu
  }
}

</script>

<style lang="scss" scoped>
@media screen and (max-width: 991px) {
  .row-mobile{
    display: flex;
    flex-direction: row;
  }
}
    .mobile-bar {
      display: none;
    }
#navbar-menu {
  font-family: 'Montserrat-Regular';
  .nav-item {
    &.profile-menu {
        border-right: 1px solid rgba(0, 0, 0, 0.125);
        .help {
            .icon {
                color: #22304c;
                width: 24px;
                height: 24px;
            }
            span {
                color: #22304c;
            }
            &:hover {
                .icon {
                    color: #596378;
                    transition: all 150ms ease-in-out;
                }
                span {
                    color: #596378;
                    transition: all 150ms ease-in-out;
                }
            }
        } 
    }
    transition: all 0ms ease-in-out;
    .nav-link, .dropdown-toggle  {
      color: #22304c;
      font-weight: bold;
      padding: 0.5rem;
      &:hover {
        color: #596378;
        transition: all 150ms ease-in-out;
      }
    }
    .dropdown-toggle {
      text-decoration: none;
      position: relative;
      .open {
        display:none;
      }
      .close { 
        display:inline;
      }
      a span {
        margin: 0 0 0 2px;
      }
    }
    .show {
      .open {
        display: inline;
      }
      .close { 
        display:none;
      }
    }
    .icon {
        color: #22304c;
        width: 24px;
        height: 24px;
        &:hover {
            color: #596378;
            transition: all 150ms ease-in-out;
        }
    }
    .dropdown-menu {
      border: none;
      text-align: left;
      -webkit-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      -moz-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      .dropdown-item {
        font-weight: normal;
        font-size: 0.8rem;
        &:hover {
          color: #22304c;
          background-color: transparent;
        }
      }
    }
    .signup-btn {
      text-align: center;
      min-width: 130px;
      width: auto;
      -webkit-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      -moz-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      background-color: #ffcd6c;
      font-weight: bold;
    }
    .platform-menu {
      -webkit-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      -moz-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      .dropdown-item {
        font-size: 0.875rem;
        font-weight: 600;
        &:hover {
          color: #596378;
          transition: all 150ms ease-in-out;
        }
      }
    }
    .signup-menu {
      text-align: center;
      background-color: #ffcd6c;
      -webkit-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      -moz-box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      box-shadow: 0px 10px 25px rgba(34, 48, 76, 0.07);
      .dropdown-item {
        font-size: 0.875rem;
        font-weight: 600;
        &:hover {
          color: #596378;
          transition: all 150ms ease-in-out;
        }
      }
    }
    @media screen and (max-width: 1280px) and (max-height: 600px) {
        .signup-btn {
            box-shadow: 0px 8px 16px rgba(34, 48, 76, 7%);
            -webkit-box-shadow: 0px 8px 16px rgba(34, 48, 76, 0.07);
            -moz-box-shadow: 0px 8px 16px rgba(34, 48, 76, 0.07);
        }
    }
  }
  .nav-item.upper-header-mobile {
    display: none;
    .flag-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 30px;
        margin: 0px;
        font-weight: 600;
        font-size: 0.75rem;
        .title {
            margin-right: 0.5rem;
        }
       .flag {
            display: flex;
            align-items: center;
            img {
                height: 14px;
            }
        }
    }
    .flag-container:hover {
      cursor:pointer;
      text-decoration: underline;

    }
    .selected {
      text-decoration: underline;
    }
    .vl {
      border-left: 1px solid #B4BABD;
      height: auto;
      margin: 2px 0;
    }
  }
  .language-picker {
    .vl {
        height: auto;
        margin: 6px 0;
    }
    .flag-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        font-weight: 600;
        font-size: 0.75rem;
        .title {
            margin-right: 0.5rem;
        }
        .flag {
            display: flex;
            align-items: center;
            img {
                height: 24px;
                width: 24px;
                border-radius: 50%;
            } 
        }
    }
    .flag-container:hover {
        cursor:pointer;
        text-decoration: underline;
    }
    .selected {
        border: solid 2px #00A0FF;
        border-radius: 50%;
    }
  }
  :deep(#dropdown-menu) {
    a {
        text-decoration: none;
        color: #22304c;
        font-weight: 600;
        &.action {
            cursor: pointer;
        }   
    }
    .menu, .sub-menu, .collapsable-menu {
        font-size: 0.875rem;
        a {
            text-decoration: none;
            color: #22304c;
            font-weight: 600;
            &.action {
                cursor: pointer;
            }   
        }
    }
    .collapsable-menu {
        border-top: 1px solid #E6E6E6;
    }
  }
  @media screen and (max-width: 991px) {
    .mobile-bar{
          overflow-x: auto;
          display: block;
        }
    .nav-item.upper-header-mobile {
      display: flex;
    }
    .platform-menu {
      flex-direction: column;
      align-items: center;
      background: transparent;
      box-shadow: none !important;
      .dropdown-item {
        width: auto;
      }
    }
    .platform-menu.show {
      display: flex;
    }
  }
}

</style>

<template>
  <div :id="'top-navigation' + parent" class="top-navigation py-3 px-3">
    <div class="d-flex flex-wrap gap-2  gap-md-1 gap-xl-0 align-items-center justify-content-between top-navigation-inner w-100 isMobile">
        <div class="d-flex  gap-2 flex-wrap justify-content-center justify-content-lg-start isMobile">
            <div v-for="(item, index) in navigationItems" :key="item.name + '-' +index" class="d-flex flex-row" :class="{'dropdown-divider my-1' : item.value === 'dropdown-divider', 'external-link': !item.url}">
                <Skeletor v-if="userCapability < 0 && item.url" class="menu-item menu-link rounded-2" width="120" height="25"/>
                <DropdownMenu class="menu-item" v-else-if="item.children != null" :navItem="item" :userCapability="userCapability"/>
                <div v-else-if="item.name !== null && item.url" class="d-flex flex-row">
                    <custom-link  class="menu-item menu-link" 
                        :class="{'disabled-by-capability': userCapability < (item.minimumCapability ? item.minimumCapability : 0) || isExcluded(item)}" 
                            @click="checkCapabilities(userCapability < (item.minimumCapability ? item.minimumCapability : 0) || isExcluded(item))" 
                            :to="userCapability < (item.minimumCapability ? item.minimumCapability : 0) || 
                            isExcluded(item) ? '' : item.url" replace :active-class="userCapability < (item.minimumCapability ? item.minimumCapability : 0) || 
                            isExcluded(item) ? '' : 'poppins-bold underline'" inactive-class="poppins-regular">
                        <span @click="userCapability < (item.minimumCapability ? item.minimumCapability : 0) ? checkCapabilities(userCapability < (item.minimumCapability ? item.minimumCapability : 0) || 
                            isExcluded(item)) : switchTab(null, null)">{{$t(item.name)}}</span>
                    </custom-link>
                    <div class="vertical-separator mx-1 mx-sm-2 mx-xxl-3" v-if="index != (navigationItems.length - 1)"></div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
            <div v-for="(item, index) in navigationItems" :key="item.name + '-' +index" class="d-flex flex-row" :class="{'dropdown-divider my-1' : item.value === 'dropdown-divider', 'external-link': !item.url}">
                <Skeletor v-if="userCapability < 0 && !item.url" class="menu-item menu-link rounded-2" width="120" height="25"/>
                <div v-else-if="item.name !== null && !item.url" class="d-flex flex-row">
                    <span class="menu-link link text-capitalize pointer underline" @click="emitAction(item.action)">{{$t(item.name)}}</span>
                    <div class="vertical-separator mx-1 mx-sm-2 mx-xxl-3" v-if="index != (navigationItems.length - 1)"></div>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import DropdownMenu from "@/components/platform/general/DropdownMenu.vue";
export default {
  data() {
    return {
      currentTab: 'lookup-fund',
      parent: '',
    }
  },
  methods: {
    switchTab(dropdown, ref) {
      if(dropdown !== null) {
        if (this.$refs[ref] && this.$refs[ref][0].classList.contains('show')) {
          this.$refs[ref][0].classList.remove('show');
        }
      }
    },
    checkCapabilities(check) {
      this.$store.dispatch('setCheckingCapabilityStatus', check);
    },
    emitAction(action) {
        this.$emit('actionEmitted', action);
    },
    isExcluded(item) {
        return item.excludedCapabilities ? item.excludedCapabilities.includes(this.userCapability) : false;
    }
  },
  props: {
    navigationItems: Array,
    preSelectedTab: null,
    parentContainer: null
  },
  computed: {
    userCapability() {
      return this.$store.getters['profile/getUserCapability'];
    }
  },
  mounted() {
    this.currentTab = this.preSelectedTab ? this.preSelectedTab : this.currentTab;
    this.parent = this.parentContainer ? this.parentContainer : '';
  },
  components: {
    DropdownMenu
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 991px){
  .isMobile{
    flex-direction: column!important;
  }
  
}
@media screen and (min-width: 992px)
{
  .isMobile{
    flex-direction: row!important;
  }
  
}

$black: #2E2B42;
$white: #FFFFFF;
#top-navigation {
  background-color: $white;
  min-height: 48px;
  .external-link {
    margin-left: auto;
  }
  :deep(.menu-item) {
    text-decoration: none;
    color: $black;
    a {
      text-decoration: none;
      color: $black;
    }
    span{
      white-space: nowrap;
    }
  }
  :deep(#dropdown-menu) {
    .trigger, .btn {
        font-size: 1.15rem;
    }
  }
}
.top-navigation{
  &:not(#top-navigation){
    background-color: $white;
    min-height: 48px;
    max-height: 60px;
    .top-navigation-inner{
      flex-direction: column!important;
    }
    .vertical-separator{
      display: none;
    }
    :deep(.menu-item) {
      text-decoration: none;
      color: $black;
      a {
        text-decoration: none;
        color: $black;
      }
      span{
        white-space: nowrap;
      }
      .dropdown-menu {
        background: $white;
        box-shadow: 0px 39px 40px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        border: none;
        .dropdown-item {
          font-size: 0.90rem;
          &:hover {
            background-color: transparent;
          }
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
  }
}

</style>
// Demo portfolio global variable
// TODO: figure out how to base off environment for the ID
const DEMO_PORTFOLIO_IDS = {
    prod: '4d49015b-d356-4329-9dc7-5c38398cfa39',
    dev: '13270b3a-b769-43c9-b023-13fcc32cb578'
}

const DEMO_PORTFOLIO_ITEMS = {
    dev: {
        "id": "39559983-1cfc-45bd-bd65-4e9e3ffcb88c",
        "portfolioId": 395,
        "input": "URTH",
        "fundId": 289,
        "holdingId": null,
        "weight": "100",
        "isBenchmark": false
    },
    prod: {
        "id": "7de81edb-0974-4ad2-bd35-71a4e1f78019",
        "portfolioId": 395,
        "input": "URTH",
        "fundId": 289,
        "holdingId": null,
        "weight": "100",
        "isBenchmark": false
    }
}


export const DEMO_PORTFOLIO_ID = DEMO_PORTFOLIO_IDS.prod;
export const DEMO_PORTFOLIO_ITEM = DEMO_PORTFOLIO_ITEMS.prod;
const now = new Date().toISOString();
export const DEMO_PORTFOLIO_META = {
    "id": DEMO_PORTFOLIO_ID,
    "firstname": "World",
    "lastname": "Portfolio",
    "email": "",
    "phone": "",
    // "imageUrl": "h/ttps://dev-physisinvest-profile-photo.s3.us-east-1.amazonaws.com/af3339bc66fe78c47c30d3a66ab55935.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAZRJRDVG64PO7LAJU%2F20241013%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241013T014430Z&X-Amz-Expires=86400&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEFoaCXVzLWVhc3QtMSJGMEQCIB8txvkvB0HfpO16VI8BluAE5jPlsdQuWRbFigW3F9tXAiA0Q1D2dfR8hZvc8wJ%2BPbq3gUlhbafXsqcEg%2BMtxLmYUCqBAwiz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAMaDDY1NTYyMjM4NDA2MSIMDitQiovNCWIYsdA%2FKtUC6epfxJOng%2BnEbHBQEreM9EPoCJ9T5nDk2ieWZBaEw1ZbqUVSEQ1tSEn6Y0EqiBAI2h3jCt9txFQe5TOmYS1CMMIvelLtlirLwzRDlrgGBuAEU2s9pYQiRcr87plyey1SU6qhFGIyYUjkNW2HYvQSkXYpmmr1kFWuDQTrJI95Mw4gLpeAbEtv9CauYi%2BSRTVAblaANPePSmUoKiSUWM9U1SkuYPzFKZX97r4GmYFSYDwzSVW6%2FNTLHimaW1hRJ7k3ZzLROjy%2FCfVIoeA5ekEFYOaNh3ZT7o2jT6S36DtK3ICgjdZo8WveC8GT1mYpZ7pB2BjYCYuw1ljz7x9Uxx3tD2i787FRI4RNjEW4F8qHs3U1FbcwofRF%2FfZEBQcWXgnJnWEmEt%2F%2Bxn8lqCu9g9XfQbbHZPjL3CRCGMehqnXfcOFgCg31Qfpq8pCdlniE3cHMmTBBmYcw9cusuAY6nwHwAcLtoZqRx7i%2BQTz%2B0ue9Q4Haw0LpkPtFbCmeB1BybmpbQUYKlaZAUb2Yd9N0iDHLIXxGYzWlir6T3vD50eajy%2BJozP%2BjKAZDVlQbC4heFo0sjhw2fiOiMMD5%2F%2BSqfhczlohIZ0YuTKr6xAFDi491guzjZQvBarIpUsnARxAyCEP0gEt7eKvWkckQpwySL0bDtUiNGmYgQDfJu1FLY40%3D&X-Amz-Signature=faa17b9e9e131eced5ff05437684faa53cfea4c677926ad4210551899ed4cc56&X-Amz-SignedHeaders=host&x-id=GetObject",
    "imageUrl": "world-portfolio",
    // "imageUrlStatic": "@/assets/images/world-portfolio.png",
    // "imageUrl": "/img/physis-logo-blue.28812fb9.webp",
    "portfolio": {
        "investmentDay": "2019-09-10",
        "investmentAmount": "2700000000",
        "portfolio": {
            "items": [
                DEMO_PORTFOLIO_ITEM
            ]
        },
        "benchmark": {
            "items": []
        },
        "updatedAt": "2024-10-13T00:52:14.478Z",
        "paiUpdatedAt": now,
        "isOutdated": false
    }
};
export const isDemoPortfolio = () => {return window.location.href.indexOf(DEMO_PORTFOLIO_ID) >= 0};


import {getSessionToken} from '../http/auth'
import {API} from '../http/api'
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();
function returnElement(array, name) {
    if (array.length > 1) {
      let totalIdx = array.findIndex(entry => entry.name === name);
      let total = array.find(entry => entry.name === name);
      if (total && totalIdx) {
        array.splice(totalIdx, 1);
        return total;
      }
    }
    return null;
  }
  

const pdf = {
    namespaced: true,
    state: {
        clientPerformance: {},
        portfolio: {
            portfolio: {},
            benchmark: {}
        },
        countriesAllocation: {
            portfolio: [],
            benchmark: []
        },
        esgPdf: {
            portfolio: {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 5,
                flag: "pdf",
                total: 1
            },
            benchmark: {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 5,
                flag: "pdf",
                total: 1
            }
        },
        pdfPerformanceReturns: {
            portfolio: {
                cumulative: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                },
                rolling: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                }
            },
            benchmark: {
                cumulative: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                },
                rolling: {
                    lastWeek: 0,
                    lastMonth: 0,
                    lastYear: 0
                }
            },
        },
        products: {
            categories: [],
            products: []
        },
        exclusionsWatchlistPdf: {},
        PAIIndicators: [],
        PAIIndicators2: [],
        PAIIndicators3: [],
        euTaxonomySummary: {},
        redCompaniesByIndicator: [],
        redCompaniesByWeight: [],
        ready: {
            performance: false,
            portfolio: false,
            countriesAllocation: false,
            returns: false,
            dailyPerformance: false,
            esg: false,
            impactIndicators: false,
            products: false,
            exclusions: false,
            redCompaniesByWeight: false,
            redCompaniesByIndicator: false,
            pai: false,
            pai2: false,
            pai3: false,
            taxonomy: false
        },
        error: false,
        timeout: false
    },
    mutations: {
        setPortfolioPerformanceData(state, payload) {
            state.clientPerformance.PortfolioPerformanceData = payload;
        },
        setPortfolioData(state, payload) {
            state.portfolio = payload;
        },
        setCountriesAllocation(state, payload) {
            state.countriesAllocation = payload;
        },
        setPdfPerformanceReturns(state, payload) {
            state.pdfPerformanceReturns = payload;
        },
        setDailyPerformanceData(state, payload) {
            state.clientPerformance.DailyPerformanceData = payload
        },
        setESGPdfDataPortfolio(state, payload) {
            state.esgPdf.portfolio = payload;
        },
        setESGPdfDataBenchmark(state, payload) {
            state.esgPdf.benchmark = payload;
        },
        setImpactIndicatorsData(state, payload) {
            state.clientPerformance.ImpactIndicatorsData = payload;
        },
        setProductsData(state, payload) {
            state.products = payload;
        },
        setPdfExclusionsWatchlist(state, payload) {
            state.exclusionsWatchlistPdf = payload;
        },
        setRedCompaniesByIndicator(state, payload) {
            state.redCompaniesByIndicator = payload;
        },
        setPAIIndicators(state, payload) {
            state.PAIIndicators = payload;
        },
        setPAIIndicators2(state, payload) {
            state.PAIIndicators2 = payload;
        },
        setPAIIndicators3(state, payload) {
            state.PAIIndicators3 = payload;
        },
        setTaxonomySummaryData(state, payload) {
            state.euTaxonomySummary = payload;
        },
        setPortfolioGeneralData(state, payload) {
            state.clientPerformance.PortfolioGeneralData = payload;
        },
        setRedCompaniesByWeight(state, payload) {
            state.redCompaniesByWeight = payload;
        },
        setClientPerformanceData(state, payload) {
            state.clientPerformance = payload;
        },
        setSectionReady(state, payload) {
            state.ready[payload] = true;
        },
        resetReady(state) {
            for (const key in state.ready) {
                state.ready[key] = false;
            } 
        },
        setError(state, payload) {
            state.error = payload;
        },
        setTimeout(state, payload) {
            state.timeout = payload;
        }
    },
    actions: {
        async loadPdfEndpoints({dispatch}, data) {
            await dispatch('loadPortfolioPerformance', data).then( async () => {
                await dispatch('loadPortfolio', data).then( async () => {
                    await dispatch('loadCountriesAllocation', data).then( async () => {
                        await dispatch('loadDailyPerformancePdf', data).then( async () => {
                            await dispatch('loadDailyPerformance', data).then( async () => {
                                await dispatch('loadEsgData', data).then( async () => {
                                    await dispatch('loadEsgDataBenchmark', data).then(async () => {
                                        await dispatch('loadImpactIndicators', data).then(async () => {
                                            await dispatch('loadSustainableProducts', data).then(async () => {
                                                await dispatch('loadExclusionsAndWatchlist', data).then(async () => {
                                                    await dispatch('loadRedCompaniesByWeight', data).then(async () => {
                                                        await dispatch('loadRedCompaniesByIndicator', data).then(async () => {
                                                            await dispatch('loadPAIIndicators', {id: data.selectedClient.id, number: data.number}).then(async () => {
                                                                await dispatch('loadPAIIndicators', {id: data.selectedClient.id, number: data.number + 2}).then(async () => {
                                                                    await dispatch('loadPAIIndicators', {id: data.selectedClient.id, number: data.number + 3}).then(async () => {
                                                                        await dispatch('loadEUTaxonomyData', data).then(async () => {
                
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        },
        async loadPortfolioPerformance({commit, dispatch}, data) {
            if(data === undefined) {
                return;
            }
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getPortfolioPerformance',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    }
                )
                .then(async(performance) => {
                    if (performance && Object.keys(performance).length > 0) {
                        let results = {};
                        results.data = performance;
                        commit("setPortfolioPerformanceData", results);
                        commit("setSectionReady", 'performance')
                        resolve();
                    } else {
                        toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                    }
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            });
            return promise;
        },
        async loadPortfolio({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getPortfolioByClientV2',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session
                        }
                    }
                ).then(async (portfolio) => {
                    commit("setPortfolioGeneralData", portfolio);
                    commit('setPortfolioData', portfolio);
                    commit("setSectionReady", 'portfolio');
                    resolve();
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                })
            });
            return promise;
        },
        async loadCountriesAllocation({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getCountryPortfolioAllocation',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session
                        }
                    }
                ).then(async (allocation) => {
                    commit('setCountriesAllocation', allocation);
                    commit("setSectionReady", 'countriesAllocation');
                    resolve();
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                })
            })
            return promise;
        },
        async loadDailyPerformancePdf({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getDailyPerformancePDF',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    })
                    .then(async (dailyPerformancePdf) => {
                        const returns = {
                            portfolio: {
                                cumulative: {
                                    lastWeek: dailyPerformancePdf.portfolio.total.weekAgoCumulative ? dailyPerformancePdf.portfolio.total.weekAgoCumulative : 0,
                                    lastMonth: dailyPerformancePdf.portfolio.total.monthAgoCumulative ? dailyPerformancePdf.portfolio.total.monthAgoCumulative : 0,
                                    lastYear: dailyPerformancePdf.portfolio.total.yearToDateCumulative ? dailyPerformancePdf.portfolio.total.yearToDateCumulative : 0,
                                },
                                rolling: {
                                    lastWeek: dailyPerformancePdf.portfolio.total.weekAgoRolling ? dailyPerformancePdf.portfolio.total.weekAgoRolling : 0,
                                    lastMonth: dailyPerformancePdf.portfolio.total.monthAgoRolling ? dailyPerformancePdf.portfolio.total.monthAgoRolling : 0,
                                    lastYear: dailyPerformancePdf.portfolio.total.yearToDateRolling ? dailyPerformancePdf.portfolio.total.yearToDateRolling : 0,
                                }
                            },
                            benchmark: {
                                cumulative: {
                                    lastWeek: dailyPerformancePdf.benchmark.total.weekAgoCumulative ? dailyPerformancePdf.portfolio.total.weekAgoCumulative : 0,
                                    lastMonth: dailyPerformancePdf.benchmark.total.monthAgoCumulative ? dailyPerformancePdf.portfolio.total.monthAgoCumulative : 0,
                                    lastYear: dailyPerformancePdf.benchmark.total.yearToDateCumulative ? dailyPerformancePdf.portfolio.total.yearToDateCumulative : 0,
                                },
                                rolling: {
                                    lastWeek: dailyPerformancePdf.benchmark.total.weekAgoCumulative ? dailyPerformancePdf.portfolio.total.weekAgoRolling : 0,
                                    lastMonth: dailyPerformancePdf.benchmark.total.monthAgoRolling ? dailyPerformancePdf.portfolio.total.monthAgoRolling : 0,
                                    lastYear: dailyPerformancePdf.benchmark.total.yearToDateRolling ? dailyPerformancePdf.portfolio.total.yearToDateRolling : 0,
                                }
                            },
                        }
                        commit('setPdfPerformanceReturns', returns);
                        commit("setSectionReady", 'returns');
                        resolve();
                    })
                    .catch((err) => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            if (err.message.includes('504')) 
                                commit('setTimeout', true);
                            commit('setError', true);
                            toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                            throw err;
                        }
                    });
            });
            return promise;
        },
        async loadDailyPerformance({commit, dispatch}, data) {
            let session = await getSessionToken();
            if(data === undefined){
                return;
            }
            let fromDate = data.fromDate instanceof Date ? (data.fromDate.getFullYear() + '-' + (data.fromDate.getMonth() + 1) + '-' + data.fromDate.getDate()) : data.fromDate;
            let toDate = data.toDate instanceof Date ? (data.toDate.getFullYear() + '-' + (data.toDate.getMonth() + 1) + '-' + data.toDate.getDate()) : data.toDate;
            let promise = new Promise((resolve) => {
                API.get(
                    'getDailyPerformance',
                    `${data.selectedClient && data.selectedClient.id ? data.selectedClient.id : ''}?from= + ${(fromDate)}&to=${(toDate)}`,
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    }
                ).then(async (dailyPerformance) => {
                    if (dailyPerformance && Object.keys(dailyPerformance).length > 0) {
                        let results = {};
                        results.data = dailyPerformance;
                        results.fromDate = data.fromDate;
                        results.toDate = data.toDate;
                        commit("setDailyPerformanceData", results);
                        commit("setSectionReady", 'dailyPerformance');
                        resolve();
                    } else {
                        toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                    }
                }).catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            })
            return promise;
        },
        async loadEsgData({commit, dispatch}, data) {
            let session = await getSessionToken();
            let esgPdfParameters = this.getters["pdf/getESGPdfData"].portfolio;
            let promise = new Promise((resolve) => {
                API.get(
                    'getESGV2',
                    `${data.selectedClient && data.selectedClient.id ? data.selectedClient.id : ''}?page=${data.esgData.companies.page}&per_page=${data.esgData.companies.perPage ? data.esgData.companies.perPage : esgPdfParameters.perPage}&order_by=${data.esgData.companies.orderBy}&order=${data.esgData.companies.order}&benchmark=${false}&find=${data.esgData.companies.find}`,
                    {
                        headers: {
                            Authorization: session
                        }
                    }).then(async (replyESG) => {
                        if (replyESG.esg.page > 1) {
                            esgPdfParameters.e.companies = [...esgPdfParameters.e.companies, ...replyESG.esg.e.companies];
                            esgPdfParameters.s.companies = [...esgPdfParameters.s.companies, ...replyESG.esg.s.companies];
                            esgPdfParameters.g.companies = [...esgPdfParameters.g.companies, ...replyESG.esg.g.companies];
                        } else {
                            esgPdfParameters.e.portfolioTotal = returnElement(replyESG.esg.e.companies, 'TOTAL');
                            esgPdfParameters.s.portfolioTotal = returnElement(replyESG.esg.s.companies, 'TOTAL')
                            esgPdfParameters.g.portfolioTotal = returnElement(replyESG.esg.g.companies, 'TOTAL');
                            esgPdfParameters.e.companies = replyESG.esg.e.companies;
                            esgPdfParameters.s.companies = replyESG.esg.s.companies;
                            esgPdfParameters.g.companies = replyESG.esg.g.companies;
                            esgPdfParameters.e.indicators = replyESG.esg.e.indicators;
                            esgPdfParameters.s.indicators = replyESG.esg.s.indicators;
                            esgPdfParameters.g.indicators = replyESG.esg.g.indicators;
                        }
                        esgPdfParameters.find = replyESG.esg.find;
                        esgPdfParameters.order = replyESG.esg.order;
                        esgPdfParameters.orderBy = replyESG.esg.orderBy;
                        esgPdfParameters.page = replyESG.esg.page;
                        esgPdfParameters.perPage = replyESG.esg.perPage;
                        esgPdfParameters.total = replyESG.esg.total;
                        commit('setESGPdfDataPortfolio', esgPdfParameters);
                        commit("setSectionReady", 'esg');
                        resolve();
                    }).catch(err => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            if (err.message.includes('504')) 
                                commit('setTimeout', true);
                            commit('setError', true);
                            toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                            throw err;
                        }
                    })
                });
            return promise;
        },
        async loadEsgDataBenchmark({commit, dispatch}, data) {
            let session = await getSessionToken();
            let esgPdfParameters = this.getters["pdf/getESGPdfData"].benchmark;
            let promise = new Promise((resolve) => {
                API.get(
                    'getESGV2',
                    `${data.selectedClient && data.selectedClient.id ? data.selectedClient.id : ''}?page=${data.esgData.companies.page}&per_page=${data.esgData.companies.perPage ? data.esgData.companies.perPage : esgPdfParameters.perPage}&order_by=${data.esgData.companies.orderBy}&order=${data.esgData.companies.order}&benchmark=${true}&find=${data.esgData.companies.find}`,
                    {
                        headers: {
                            Authorization: session
                        }
                    }).then(async (replyESG) => {
                        if (replyESG.esg.page > 1) {
                            esgPdfParameters.e.companies = [...esgPdfParameters.e.companies, ...replyESG.esg.e.companies];
                            esgPdfParameters.s.companies = [...esgPdfParameters.s.companies, ...replyESG.esg.s.companies];
                            esgPdfParameters.g.companies = [...esgPdfParameters.g.companies, ...replyESG.esg.g.companies];
                        } else {
                            esgPdfParameters.e.portfolioTotal = returnElement(replyESG.esg.e.companies, 'TOTAL');
                            esgPdfParameters.s.portfolioTotal = returnElement(replyESG.esg.s.companies, 'TOTAL')
                            esgPdfParameters.g.portfolioTotal = returnElement(replyESG.esg.g.companies, 'TOTAL');
                            esgPdfParameters.e.companies = replyESG.esg.e.companies;
                            esgPdfParameters.s.companies = replyESG.esg.s.companies;
                            esgPdfParameters.g.companies = replyESG.esg.g.companies;
                            esgPdfParameters.e.indicators = replyESG.esg.e.indicators;
                            esgPdfParameters.s.indicators = replyESG.esg.s.indicators;
                            esgPdfParameters.g.indicators = replyESG.esg.g.indicators;
                        }
                        esgPdfParameters.find = replyESG.esg.find;
                        esgPdfParameters.order = replyESG.esg.order;
                        esgPdfParameters.orderBy = replyESG.esg.orderBy;
                        esgPdfParameters.page = replyESG.esg.page;
                        esgPdfParameters.perPage = replyESG.esg.perPage;
                        esgPdfParameters.total = replyESG.esg.total;
                        commit('setESGPdfDataBenchmark', esgPdfParameters);
                        commit("setSectionReady", 'esg');
                        resolve();
                    }).catch(err => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            if (err.message.includes('504')) 
                                commit('setTimeout', true);
                            commit('setError', true);
                            toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                            throw err;
                        }
                    })
                });
            return promise;
        },
        async loadImpactIndicators({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                if(data === undefined) {
                    return;
                }
                API.get(
                    'getImpactIndicatorsSummary',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    }
                ).then((reply) => {
                    if (reply && Object.keys(reply).length > 0) {
                        let results = {};
                        results.data = reply;
                        commit("setImpactIndicatorsData", results);
                        commit("setSectionReady", 'impactIndicators');
                        resolve();
                    } else {
                        toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                    }
                }).catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            });
            return promise;
        },
        async loadSustainableProducts({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getProductsV2',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session
                        }
                    }
                ).then(products => {
                    commit('setProductsData', products);
                    commit("setSectionReady", 'products');
                    resolve();
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            });
            return promise;
        },
        async loadExclusionsAndWatchlist({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getExclusionsWatchListPdf',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    }
                ).then((reply) => {
                    commit('setPdfExclusionsWatchlist', reply);
                    commit("setSectionReady", 'exclusions');
                    resolve();
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            });
            return promise;
        },
        async loadRedCompaniesByWeight({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getRedCompaniesByWeight',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    }
                ).then((reply) => {
                    commit('setRedCompaniesByIndicator', reply.data);
                    commit("setSectionReady", 'redCompaniesByWeight');
                    resolve();
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            })
            return promise;
        },
        async loadRedCompaniesByIndicator({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getCompaniesByIndicator',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                            Authorization: session,
                        },
                        body: {}
                    }
                ).then((reply) => {
                    commit('setRedCompaniesByWeight', reply.data);
                    commit("setSectionReady", 'redCompaniesByIndicator');
                    resolve();
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
            });
            return promise;
        },
        async loadPAIIndicators({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getPAIIndicatorsData' + data.number,
                    data.id ? data.id : '',
                    {
                    headers: {
                        Authorization: session
                    }
                    }
                ).then(res => {
                    commit('setPAIIndicators'+data.number, res);
                    commit("setSectionReady", 'pai' + data.number);
                    resolve();
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                })
            })
            return promise;
        },
        async loadEUTaxonomyData({commit, dispatch}, data) {
            let session = await getSessionToken();
            let promise = new Promise((resolve) => {
                API.get(
                    'getEuTaxonomySummary',
                    data.selectedClient && data.selectedClient.id ? data.selectedClient.id : '',
                    {
                        headers: {
                        Authorization: session
                        }
                    }
                ).then(res => {
                    commit('setTaxonomySummaryData', res);
                    commit("setSectionReady", 'taxonomy');
                    resolve();
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        if (err.message.includes('504')) 
                            commit('setTimeout', true);
                        commit('setError', true);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                })
            })
            return promise;
        },
        resetESGPdfData({commit}) {
            commit('setESGPdfDataPortfolio', {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 5,
                flag: "pdf",
                total: 1
            });
            commit('setESGPdfDataBenchmark', {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 5,
                flag: "pdf",
                total: 1
            });
        },
        resetExclusionsAndWatchlistPDf({commit}) {
          commit('setPdfExclusionsWatchlist', {});
        },
        resetCompaniesByRedIndicator({commit}) {
          commit('setRedCompaniesByIndicator', []);
        },
        resetCompaniesByRedWeight({commit}) {
          commit('setRedCompaniesByWeight', []);
        },
        resetSustainableProducts({commit}) {
            commit('setProductsData', {
              categories: [],
              products: []
            },)
        },
        resetEUTaxonomySummaryData({commit}) {
            commit('setTaxonomySummaryData', {})
        },
        resetClientPortfolio({commit}) {
            commit("setPortfolioData", {
                portfolio: {},
                benchmark: {}
            },)
        },
        resetClientPerformanceData({commit}) {
            commit("setClientPerformanceData", {});
        },
        resetClientPerformancePdfReturns({commit}) {
            commit("setPdfPerformanceReturns", {
                portfolio: {
                    cumulative: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    },
                    rolling: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    }
                },
                benchmark: {
                    cumulative: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    },
                    rolling: {
                        lastWeek: 0,
                        lastMonth: 0,
                        lastYear: 0
                    }
                },
            } );
        },
        resetPDFData({dispatch}) {
            dispatch('resetPdfFlags');
            dispatch('resetESGPdfData');
            dispatch('resetExclusionsAndWatchlistPDf');
            dispatch('resetCompaniesByRedIndicator');
            dispatch('resetSustainableProducts');
            dispatch('resetClientPortfolio');
            dispatch('resetClientPerformanceData');
            dispatch('resetClientPerformancePdfReturns');
        },
        resetPdfFlags({commit}) {
            commit('resetReady');
            commit('setError', false);
            commit('setTimeout', false);
        }
    },
    getters: {
        getPortfolioPerformanceData: state => {
            return state.clientPerformance.PortfolioPerformanceData;
        },
        getPortfolioGeneralData: state => {
            return state.clientPerformance.PortfolioGeneralData;
        },
        getDailyPerformanceData: state => {
            return state.clientPerformance.DailyPerformanceData;
        },
        getESGPdfData: state => {
            return state.esgPdf;
        },
        getPdfPerformanceReturns: state => {
            return state.pdfPerformanceReturns;
        },
        getPortfolioSummary: state => {
            let data = {};
            data.portfolio = {distribution: state.portfolio.portfolio.distribution, summary: state.portfolio.portfolio.summary};
            data.benchmark = {distribution: state.portfolio.benchmark.distribution, summary: state.portfolio.benchmark.summary};
            return data;
        },
        getPortfolioSDGs: state => {
            let data = {};
            data.portfolio = state.portfolio.portfolio.goalsDistribution;
            data.benchmark = state.portfolio.benchmark.goalsDistribution;
            return data;
        },
        getPAIIndicatorsData: state => {
            return { 
                principal: state.PAIIndicators, 
                environmental: state.PAIIndicators2, 
                additional: state.PAIIndicators3 
            };
        },
        getImpactIndicatorsData: state => {
            return state.clientPerformance.ImpactIndicatorsData;
        },
        getPdfExclusionsAndWatchlist: state => {
            return state.exclusionsWatchlistPdf;
        },
        getRedCompaniesByIndicator: state => {
            return state.redCompaniesByIndicator;
        },
        getSustainableProductsPdf: state => {
            let sustainableProducts = JSON.parse(JSON.stringify(state.products.products));
            sustainableProducts.sort((a,b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (sustainableProducts.length >= 5) {
                sustainableProducts = sustainableProducts.splice(0, 5);
            }
            sustainableProducts.forEach(company => {
                company.products.sort((a,b) => {
                    if (parseFloat(b.sustainableScore) > parseFloat(a.sustainableScore)) {
                        return 1;
                    }
                    if (parseFloat(b.sustainableScore) < parseFloat(a.sustainableScore)) {
                        return -1;
                    }
                    if (parseFloat(b.sustainableScore) === parseFloat(a.sustainableScore)) {
                        return 0;
                    }
                });
                if (company.products.length >= 3) {
                    company.products = company.products.splice(0, 3);
                }
            });
            return sustainableProducts;
        },
        getTaxonomySummaryData: state => {
            return state.euTaxonomySummary;
        },
        getRedCompaniesByWeight: state => {
            return state.redCompaniesByWeight;
        },
        pdfReady: state => {
            return state.ready;
        },
        getPortfolioDistribution: state => {
            let data = {};
            let distribution = state.portfolio.portfolio.distribution ? JSON.parse(JSON.stringify(state.portfolio.portfolio.distribution)) : [];
            let distributionB = state.portfolio.benchmark.distribution ? JSON.parse(JSON.stringify(state.portfolio.benchmark.distribution)) : [];
            distribution.sort((a, b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            distributionB.sort((a, b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (distribution.length >= 5) {
                distribution = distribution.splice(0, 5);
            }
            distribution.forEach(fund => {
                fund.weight = fund.weight + '%';
            })
            distributionB.forEach(fund => {
                fund.weight = fund.weight + '%';
            })
            if (distributionB.length >= 5) {
                distributionB = distributionB.splice(0, 5);
            }
            data.portfolio = {distribution: distribution ? distribution : []};
            data.benchmark = {distribution: distributionB ? distributionB : []};
            return data;
        },
        getPortfolioAssetsPdf: state => {
            let data = {};
            let assets = state.portfolio.portfolio.assets ? JSON.parse(JSON.stringify(state.portfolio.portfolio.assets)) : [];
            let assetsB = state.portfolio.benchmark.assets ? JSON.parse(JSON.stringify(state.portfolio.benchmark.assets)) : [];
            assets.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            assetsB.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            if (assets.length >= 10) {
                assets = assets.splice(0, 10);
            }
            if (assetsB.length >= 10) {
                assetsB = assetsB.splice(0, 10);
            }
            data.portfolio = {assets: assets};
            data.benchmark = {assets: assetsB};
            return data;
        },
        getPortfolioCurrenciesPdf: state => {
            let data = {};
            let currencies = state.portfolio.portfolio.currencies ? JSON.parse(JSON.stringify(state.portfolio.portfolio.currencies)) : [];
            let currenciesB = state.portfolio.benchmark.currencies ? JSON.parse(JSON.stringify(state.portfolio.benchmark.currencies)) : [];
            currencies.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            currenciesB.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            if (currencies.length >= 5) {
                currencies = currencies.splice(0, 5);
            }
            if (currenciesB.length >= 5) {
                currenciesB = currenciesB.splice(0, 5);
            }
            data.portfolio = {currencies: currencies};
            data.benchmark = {currencies: currenciesB};
            return data;
        },
        getPortfolioSectorsPdf: state => {
            let data = {};
            let sectors = state.portfolio.portfolio.sectors ? JSON.parse(JSON.stringify(state.portfolio.portfolio.sectors)) : [];
            let sectorsB = state.portfolio.benchmark.sectors ? JSON.parse(JSON.stringify(state.portfolio.benchmark.sectors)) : [];
            sectors.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            sectorsB.sort((a,b) => {
                if (parseFloat(b.value) > parseFloat(a.value)) {
                    return 1;
                }
                if (parseFloat(b.value) < parseFloat(a.value)) {
                    return -1;
                }
                if (parseFloat(b.value) === parseFloat(a.value)) {
                    return 0;
                }
            });
            if (sectors.length >= 5) {
                sectors = sectors.splice(0, 5);
            }
            if (sectorsB.length >= 5) {
                sectorsB = sectorsB.splice(0, 5);
            }
            data.portfolio = {sectors: sectors};
            data.benchmark = {sectors: sectorsB};
            return data;
        },
        getCountriesAllocation: state => {
            let countries = JSON.parse(JSON.stringify(state.countriesAllocation));
            countries.portfolio.sort((a,b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (countries.portfolio.length >= 5) {
                countries.portfolio = countries.portfolio.splice(0, 5);
            }
            countries.portfolio.forEach(country => {
                country.weight = country.weight +'%'
            });
            countries.benchmark.sort((a,b) => {
                if (parseFloat(b.weight) > parseFloat(a.weight)) {
                    return 1;
                }
                if (parseFloat(b.weight) < parseFloat(a.weight)) {
                    return -1;
                }
                if (parseFloat(b.weight) === parseFloat(a.weight)) {
                    return 0;
                }
            });
            if (countries.benchmark.length >= 5) {
                countries.benchmark = countries.benchmark.splice(0, 5);
            }
            countries.benchmark.forEach(country => {
                country.weight = country.weight +'%'
            });
            return countries;
        },
        getError: state => {
            return state.error
        },
        getTimeout: state => {
            return state.timeout
        }
    }
}

export default pdf
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './locales'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import CustomLink from '@/components/platform/general/CustomLink.vue'
import Donut from 'vue-css-donut-chart';
import "vue-css-donut-chart/dist/vcdonut.css";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';
import VueGtag from "vue-gtag";
import Vue3ColorPicker from "vue3-colorpicker";
import vClickOutside from "click-outside-vue3";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueApexCharts from "vue3-apexcharts";
import Hotjar from 'vue-hotjar'
import { DEMO_PORTFOLIO_ID } from './globals'

Bugsnag.start({
    apiKey: '1e2a42af3ea2c62a378d651b92b7ff6c',
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV,
    autoDetectErrors: true,
    enabledReleaseStages: ['production', 'staging'],
    enabledErrorTypes: {
        unhandledExceptions: true,
        unhandledRejections: true
    },
    onError: function (event) {
        event.addMetadata('info', {
            userId: store.getters['profile/getAdvisorId'],
            version: process.env.VER,
        });
        if (event.originalError.request) {
            event.addMetadata('request', {
                location: event.originalError.request.responseURL,
                url: event.originalError.config.url,
                method: event.originalError.config.method,
                headers: event.originalError.config.headers,
                body: event.originalError.config.data,
            });
        }
    }
})


const bugsnagVue = Bugsnag.getPlugin('vue')
const app = createApp(App);


app.config.globalProperties.$demoPortfolioId = DEMO_PORTFOLIO_ID;
app.config.globalProperties.$isDemoPortfolio = () => { return window.location.href.indexOf(DEMO_PORTFOLIO_ID) >= 0 }

app.component('CustomLink', CustomLink)
app.component(Skeletor.name, Skeletor)
app.use(Skeletor, { shimmer: true })
app.use(store)
app.use(router)
app.use(i18n)
app.use(Donut)
app.use(Vue3SimpleHtml2pdf)
app.use(bugsnagVue)
app.use(Vue3ColorPicker)
app.use(vClickOutside)
app.use(Hotjar, {
    id: '1697622',
    snippetVersion: 6
})
const toastOptions = {
    onMounted: (_, toastApp) => {
        // Register the router
        toastApp.use(router);
        toastApp.use(i18n)
    },
};
app.use(Toast, toastOptions)
app.use(CKEditor)
app.use(VueApexCharts)
app.use(VueGtag, {
    config: {
        appName: 'Physis Investment Platform',
        pageTrackerScreenviewEnabled: true,
        id: "G-X7RMV6K5S7"
    }
}, router)
    .mount('#app')

const auth = {
    state: {
    },
    mutations: {
    },
    actions: {
        
    },
    getterts: {
        
    }
}

export default auth
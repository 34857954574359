<template>
    <div id="website-section" class="col-12 col-md-2 mt-0 mt-md-0 offset-md-1">
        <h6 class="text-uppercase underliner pb-3">{{$t("content.websiteNav")}}</h6>
        <div class="poppins-light mb-2">
            <a href="https://physisinvestment.com/about" class="footer-link">{{$t("content.about")}}</a>
        </div>
        <div v-if="selectedLang === 'it'" class="poppins-light mb-2">
          <a href="https://physisinvestment.com/datahub" class="footer-link">{{$t("content.platform")}}</a>
        </div>
        <div v-if="selectedLang !== 'it'" class="poppins-light collapsed pointer footer-link mb-2" @click="togglePlatform()" data-bs-toggle="collapse" data-bs-target="#platform-links">
            {{$t("content.platform")}} 
            <span v-if="!platformToggle"><i class="fas fa-chevron-down"></i></span>
            <span v-else><i class="fas fa-chevron-up"></i></span>
        </div>
        <div class="collapse mb-2 ms-2" id="platform-links">
            <div class="d-flex flex-column">
                <a href="https://physisinvestment.com/institutions" class="footer-link">{{$t("content.institutions")}}</a>
            </div>
            <div class="d-flex flex-column">
                <a href="https://physisinvestment.com/advisors" class="footer-link">{{$t("content.advisors")}}</a>
            </div>
        </div>
        <div class="poppins-light mb-2">
            <a href="https://physisinvestment.com/approach" class="footer-link">{{$t("content.approach")}}</a>
        </div>
        <div class="poppins-light mb-2">
            <a href="https://insights.physisinvestment.com" class="footer-link">#Physis Insights</a>
        </div>
    </div>
    
</template>

<script>

export default {
    data () {
        return {
            aboutToggle: false,
            platformToggle: false
        }
    },
    methods: {
        toggleAbout() {
            this.aboutToggle = !this.aboutToggle;
        },
        togglePlatform() {
            this.platformToggle = !this.platformToggle;
        }
    },
    computed: {
        selectedLang() {
            return this.$i18n.locale
        }
    }
}

</script>

<style lang="scss" scoped>

</style>
<template>
    <div id="support-section" class="col-12 col-md-2 mt-4 mt-md-0">
        <h6 class="text-uppercase underliner pb-3">{{$t("content.support")}}</h6>
        <div class="poppins-light">
            <a href="https://physisinvestment.com/faq" class="footer-link">FAQs</a>
        </div>
        <div class="poppins-light">
            <a href="mailto:physis@physisinvestment.com" class="footer-link">
                {{$t("content.emailUs")}}
            </a>
        </div>        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>
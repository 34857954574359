<template>
    <div id="user-side-menu" class="card d-flex flex-column justify-content-start align-items-center w-100 h-100 mobileNav">
        <div class="d-none d-lg-flex flex-row justify-content-center align-items-center w-100">
            <div class="d-flex flex-column justify-content-center align-items-center h-100 w-100">
                <Skeletor v-if="userCapability < 0 || loadingUserImg" circle class="avatar" width="100%" height="100%" as="div"/>
                <div v-else class="d-flex flex-row avatar-container position-relative justify-content-center align-items-center h-100 w-100">
                    <div v-if="userImg" class="avatar w-100 h-100" :style="{ backgroundImage: 'url(' + userImg + ')' }"></div>
                    <div v-else class="avatar w-100 h-100" :style="{ backgroundImage: `url(${avatarPlaceholderPath})`}"></div>
                    <div class="overlay" :class="[loadingUserImg ? 'active' : '']">
                        <div v-if="!loadingUserImg" @click="triggerUpload()"><i class="icon fas fa-edit"></i></div>
                        <div v-if="loadingUserImg"><i class="icon fas fa-circle-notch fa-spin"></i></div>
                        <input id="user-profile-image" accept="image/png, image/jpg, image/jpeg, image/svg" type="file" @change="uploadUserImg($event.target.files, userEmail);" name="myfile" />
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-lg-flex flex-row justify-content-start ms-2 align-items-center w-100 my-3">
            <Skeletor v-if="userCapability < 0 || !fullName" class="rounded-2" width="100%" height="24"/>
            <span v-else class="poppins-bold font-pure-black mb-0" id="full-username">{{fullName}}</span>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center w-100">
            <div class="d-flex mobile-navbar  align-items-center justify-content-lg-start align-items-lg-start w-100">
                <!-- mobile -->
                <Skeletor v-if="userCapability < 0" class="btn my-2 d-inline-block d-lg-none rounded-2" width="100%"/>
                <div v-else class="d-inline-block d-lg-none btn-large px-0 my-2 min-w-100 dropdown mx-2" :class="{'disabled-by-capability': userCapability < 1}" @click="checkCapabilities(userCapability < 1); reverseArrow('datahub')">
                  <div class="textNav dropdown-toggle min-w-100 menu-link btn btn-large d-flex flex-row justify-content-center" role="button" id="dropdown-datahub-mobile" data-bs-toggle="dropdown" aria-expanded="false" :class="[isChildActive(datahubNavItemsMobile) ? 'btn-primary poppins-bold' : 'poppins-regular btn-outline-primary']">
                    <span class="ms-2 btnText">{{$t("content.datahub")}}</span>
                    <div v-if="datahubArrow === false" class="mx-1"><i  class="icon fas fa-chevron-down"></i></div>
                    <div v-if="datahubArrow === true" class="mx-1"><i  class="icon fas fa-chevron-up"></i></div>

                  </div>
                  <TopNavigation :navigationItems="datahubNavItemsMobile" ref="dropdown-datahub-mobile" aria-labelledby="dropdown-datahub-mobile" :parentContainer="'-dropdown-datahub-mobile'" class="dropdown-menu dropdown-datahub-mobile"/>
                </div>
                <Skeletor v-if="userCapability < 0" class="btn my-2 ms-2 rounded-2" width="100%"/>
                <div v-else class="d-inline-block d-lg-none btn-large px-0 my-2 min-w-100 dropdown mx-2" >
                    <custom-link class="btn btn-large d-flex flex-row justify-content-center menu-link min-w-100" to="/platform/portfoliohub"
                        active-class="btn-primary poppins-bold" inactive-class="poppins-regular btn-outline-primary">
                        <span>{{$t("content.portfolioHub")}}</span>
                    </custom-link>
                </div>
                <!-- <Skeletor v-if="userCapability < 0" class="btn my-2 ms-2 rounded-2" width="100%"/>
                <div v-else class="d-inline-block d-lg-none btn-large px-0 my-2 min-w-100 dropdown mx-2" :class="{'disabled-by-capability': userCapability < 1}" @click="checkCapabilities(userCapability < 1)">
                    <custom-link class="btn btn-large d-flex flex-row justify-content-center menu-link min-w-100" :class="{'disabled-by-capability': userCapability < 2}" @click="checkCapabilities(userCapability < 2)" to="/platform/portfoliohub"
                        active-class="btn-primary poppins-bold" inactive-class="poppins-regular btn-outline-primary">
                        <span>{{$t("content.portfolioHub")}}</span>
                    </custom-link>
                </div> -->

                <Skeletor v-if="userCapability < 0" class="btn my-2 d-inline-block d-lg-none rounded-2" width="100%"/>
                <div v-else class="d-inline-block d-lg-none btn-large px-0 my-2 min-w-100 dropdown mx-2" :class="{'disabled-by-capability': userCapability < 1}" @click="checkCapabilities(userCapability < 1); reverseArrow('custo')">
                  <div class="textNav dropdown-toggle min-w-100 menu-link btn btn-large d-flex flex-row justify-content-center" role="button" id="dropdown-customization-mobile" data-bs-toggle="dropdown" aria-expanded="false" :class="[isChildActive(customizationNavItemsMobile) ? 'btn-primary poppins-bold' : 'poppins-regular btn-outline-primary']">
                    <span class="ms-2 btnText">{{$t("content.customization")}}</span>
                    <div v-if="datahubCusto===false" class="mx-1"><i  class="icon fas fa-chevron-down"></i></div>
                    <div v-if="datahubCusto===true" class="mx-1"><i  class="icon fas fa-chevron-up"></i></div>
                  </div>
                  <TopNavigation :navigationItems="customizationNavItemsMobile" ref="dropdown-customization-mobile" aria-labelledby="dropdown-customization-mobile" :parentContainer="'-dropdown-customization-mobile'" class="dropdown-menu dropdown-customization-mobile"/>
                </div>

                <Skeletor v-if="userCapability < 0" class="btn my-2 ms-2 rounded-2" width="100%"/>
                <div v-else class="d-inline-block d-lg-none btn-large px-0 my-2 min-w-100 dropdown mx-2" >
                    <custom-link class="btn btn-large d-flex flex-row justify-content-center menu-link min-w-100"   to="/platform/chat"
                        active-class="btn-primary poppins-bold" inactive-class="poppins-regular btn-outline-primary">
                        <span>{{$t("ImpactChat.AI")}}</span>
                    </custom-link>
                </div>
                <!-- Desktop -->
                <Skeletor v-if="userCapability < 0" class="btn my-2 d-none d-lg-inline-block rounded-2" width="100%"/>
                <div class="d-none d-lg-flex flex-column w-100 justify-content-start align-items-start" v-else>
                    <div class="d-none d-lg-flex flex-row justify-content-between my-2 px-0 w-100 text-center">
                        <custom-link class="menu-link" :disabled="userCapability < 1" @click="triggerMethods('datahubCollapse', 1)" :to="{name: 'Datahub'}"
                            active-class="active" inactive-class="inactive">
                            <span>{{$t("content.datahub")}}</span>
                        </custom-link>
                        <div data-bs-toggle="collapse" href="#datahubMenu" role="button" aria-expanded="false" aria-controls="datahubMenu" @click="checkCollapsables('datahubCollapse')" ref="datahubCollapse" class="mx-1 collapsed"><i class="icon fas fa-chevron-down"></i></div>
                    </div>
                    <div class="collapse w-100" id="datahubMenu" ref="datahubMenu">
                        <div class="w-100 d-flex flex-column justify-content-start sub align-items-start">
                            <custom-link class="d-none ms-1 d-lg-inline-block my-2 px-0 menu-link min-w-100" :class="{'disabled-by-capability': userCapability < 1}" @click="checkCapabilities(userCapability < 1)" :to="{name: 'Lookup'}"
                                active-class="active" inactive-class="inactive">
                                <span>{{$t("content.lookup")}}</span>
                            </custom-link>
                            <custom-link class="d-none d-lg-inline-block ms-1 my-2 px-0 menu-link min-w-100" :class="{'disabled-by-capability': userCapability < 3 || userCapability == 6 || userCapability == 9}" @click="checkCapabilities(userCapability < 3 || userCapability == 6 || userCapability == 9)" :to="{name: 'Screening'}"
                                active-class="active" inactive-class="inactive">
                                <span>{{$t("content.screening")}}</span>
                            </custom-link>
                            <custom-link v-if="userCapability === 100" class="d-none d-lg-inline-block ms-1 my-2 px-0 menu-link min-w-100" :class="{'disabled-by-capability': userCapability < 100}" @click="checkCapabilities(userCapability < 1)" :to="{name: 'Matching'}"
                                active-class="active" inactive-class="inactive">
                                <span>{{$t("content.matching")}}</span>
                            </custom-link>
                        </div>
                    </div>
                </div>
                <Skeletor v-if="userCapability < 0" class="btn my-2 ms-2 rounded-2" width="100%"/>
                <!-- <template v-else-if="showPortfolioHubMenu && !sharingClient"> -->
                <template v-else-if="showPortfolioHubMenu">
                    <div class="d-none d-lg-flex flex-column w-100 justify-content-start align-items-start" >
                        <div class="d-none d-lg-flex flex-row justify-content-between my-2 px-0 w-100 text-center">
                            <custom-link class="menu-link" @click="triggerMethods('portfoliohubCollapse', 2)" :to="{name: 'Portfolio Hub'}"
                                active-class="active" inactive-class="inactive">
                                <span>{{$t("content.portfolioHub")}}</span>
                            </custom-link>
                            <!-- <custom-link class="menu-link" :disabled="userCapability < 2 || userCapability == 6" @click="triggerMethods('portfoliohubCollapse', 2)" :to="{name: 'Portfolio Hub'}"
                                active-class="active" inactive-class="inactive">
                                <span>{{$t("content.portfolioHub")}}</span>
                            </custom-link> -->
                            <div data-bs-toggle="collapse" href="#portfolioHubMenu" role="button" aria-expanded="false" aria-controls="portfolioHubMenu" @click="checkCollapsables('portfoliohubCollapse')" ref="portfoliohubCollapse" class="mx-1 collapsed"><i class="icon fas fa-chevron-down"></i></div>
                        </div>
                        <div class="collapse w-100" id="portfolioHubMenu" ref="portfolioHubMenu">
                            <div class="w-100 d-flex flex-column justify-content-start sub align-items-start">
                                <custom-link class="d-none ms-1 d-lg-inline-block my-2 px-0 menu-link min-w-100" :to="{name: 'Information', params: { id: this.selectedClient.id ? this.selectedClient.id : ' ' }}"
                                    active-class="active" inactive-class="inactive">
                                    <span>{{$t("content.information")}}</span>
                                </custom-link>
                                <custom-link class="d-none d-lg-inline-block ms-1 my-2 px-0 menu-link min-w-100" :to="{name: 'Performance Sustainable', params: { id: this.selectedClient.id ? this.selectedClient.id : ' ' }}"
                                    active-class="active" inactive-class="inactive">
                                    <span>{{$t("content.seeSustainable")}}</span>
                                </custom-link>
                                <custom-link class="d-none d-lg-inline-block ms-1 my-2 px-0 menu-link min-w-100" :class="{'disabled-by-capability': userCapability == 9  || userCapability == 0}" @click="checkCapabilities(userCapability == 9 || userCapability == 0)" :to="(userCapability == 9 || userCapability == 0) ? '' : {name: 'Financial Performance', params: { id: this.selectedClient.id ? this.selectedClient.id : ' ' }}"
                                    :active-class="userCapability == 9 || userCapability == 0 ? 'inactive' : 'active'" inactive-class="inactive">
                                    <span>{{$t("content.seeFinancial")}}</span>
                                </custom-link>
                                <custom-link class="d-none d-lg-inline-block ms-1 my-2 px-0 menu-link min-w-100" :class="{'disabled-by-capability': userCapability == 9}"  :to="{name: 'Portfolio', params: { id: this.selectedClient.id ? this.selectedClient.id : ' ' }}"
                                    :active-class="userCapability == 9 ? 'inactive' : 'active'" inactive-class="inactive">
                                    <span>{{$t("content.seePortfolio")}}</span>
                                </custom-link>
                                <div class="d-none d-lg-flex flex-column ms-1 my-2 px-0 min-w-100">
                                    <a class="pointer menu-link share" :class="{'disabled' : clientShareStatus === -1 || calculatingPAI, 'disabled-by-capability': userCapability < 4 || userCapability == 7 || userCapability == 8 || userCapability == 9}" @click="userCapability < 4 || userCapability == 7 || userCapability == 8 || userCapability == 9? checkCapabilities(true) : shareClient()">
                                        {{$t('content.portfolioShare')}}
                                    </a>
                                    <span v-show="clientShareStatus > 0" class="fs-small link-message" :class="{'teal': clientShareStatus === 1, 'red': clientShareStatus > 1}" v-html="[clientShareStatus === 1 ? this.$t('alert.success.linkCopied') : this.$t('alert.error.linkNotCopied')]"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <custom-link v-else class="d-none d-lg-inline-block my-2 menu-link min-w-100" :to="{name: 'Portfolio Hub'}"
                    active-class="active" inactive-class="inactive">
                  <span>{{$t("content.portfolioHub")}}</span>
                </custom-link>
                <!-- <custom-link v-else class="d-none d-lg-inline-block my-2 menu-link min-w-100" @click="checkCollapsables('portfoliohubCollapse')" :to="{name: 'Portfolio Hub'}"
                    active-class="active" :class="{'disabled-by-capability': userCapability < 2 || userCapability == 6 }" inactive-class="inactive">
                  <span>{{$t("content.portfolioHub")}}</span>
                </custom-link> -->
                <Skeletor v-if="userCapability < 0" class="btn my-2 d-none d-lg-inline-block rounded-2" width="100%"/>
                <custom-link v-else class="d-none d-lg-inline-block my-2 menu-link min-w-100" :class="{'disabled-by-capability': userCapability < 2 || userCapability == 6 }" @click="userCapability !== 100 ? checkCapabilities(true) : checkCollapsables(null)" :to="userCapability == 100 ? {name: 'Customization'} : ''"
                    :active-class="userCapability === 0 ? 'inactive' : 'active'" inactive-class="inactive">
                  <span>{{$t("content.customization")}}</span>
                </custom-link>
                <Skeletor v-if="userCapability < 0" class="btn my-2 d-none d-lg-inline-block rounded-2" width="100%"/>
                <custom-link  class="d-none d-lg-inline-block my-2 menu-link min-w-100" :class="{'disabled-by-capability': userCapability == 8 || userCapability == 9}"   :to="userCapability == 8 || userCapability == 9 ? '' : {name: 'Chat'}"
                    :active-class="'active'" inactive-class="inactive">
                  <span>{{$t("content.AIchat")}}</span>
                </custom-link>
            </div>
        </div>
    </div>
</template>

<script>
import { signOut} from '../../http/auth';
import avatarPlaceholderPath from '@/assets/images/avatar-placeholder.png';
import TopNavigation from '@/components/platform/navigation/TopNavigation.vue';
import { useToast } from "vue-toastification";
import { copyText } from 'vue3-clipboard';
import ToastNavigation from '@/components/platform/general/ToastNavigation.vue'

export default {
    setup() {
        const toast = useToast();
        return {toast};
    },
    data() {
        return {
            datahubArrow: false,
            datahubCusto: false,
            avatarPlaceholderPath: avatarPlaceholderPath,
            datahubNavItemsMobile: [
              {
                name: 'content.lookup',
                value: 'lookup-mobile',
                url: '/platform/datahub/lookup',
                minimumCapability: 1,
                children: null
              },
              {
                name: 'content.fund',
                value: 'screening-fund-mobile',
                url: '/platform/datahub/screening/fund',
                minimumCapability: 3,
                children: null
              },
              {
                name: 'content.company',
                value: 'screening-company-mobile',
                url: '/platform/datahub/screening/company',
                minimumCapability: 4,
                children: null
              },
              {
                name: 'content.country',
                value: 'screening-country-mobile',
                url: '/platform/datahub/screening/country',
                minimumCapability: 4,
                children: null
              },

            //   {
            //     name: 'matching',
            //     value: 'matching-mobile',
            //     url: '/platform/datahub/matching',
            //     minimumCapability: 4,
            //     children: null
            //   }
            ],
            customizationNavItemsMobile: [
                {
                    name: 'content.companyInfo',
                    value: 'companyInfo',
                    url: '/platform/customization/company_info',
                    children: null
                },
                {
                    name: 'content.api',
                    value: 'api',
                    url: '/platform/customization/api',
                    children: null
                },
                /*{
                name: 'notifications',
                value: 'notifications',
                url: '/platform/profile/notifications',
                children: null
                }*/
            ],
            profileNavItemsMobile: [
                {
                    name: 'content.accountInfo',
                    value: 'accountInfo',
                    url: '/platform/profile/account_info',
                    children: null
                },
                {
                    name: 'content.billing',
                    value: 'billing',
                    url: '/platform/profile/billing',
                    children: null
                },
                /*{
                name: 'notifications',
                value: 'notifications',
                url: '/platform/profile/notifications',
                children: null
                }*/
            ],
            clientShareStatus: 0,
            sharedClientTriggered: false
        }
    },
    props: {
      open: Boolean
    },
    computed: {
        fullName() {
            let fullName = this.$store.getters['profile/getUserFullName'];
            return fullName;
        },
        userImg() {
            let userImg = this.$store.getters['profile/getUserImg'];
            return userImg;
        },
        userEmail() {
            let userEmail = this.$store.getters['profile/getUserEmail'];
            return userEmail;
        },
        currentRoutePath() {
          return this.$route.fullPath;
        },
        loadingUserImg() {
            return this.$store.getters['profile/getLoadingUserImg'];
        },
        userCapability() {
          return this.$store.getters['profile/getUserCapability'];
        },
        selectedLang() {
            return this.$i18n.locale
        },
        sharingClient(){
          return this.$store.getters['getSharing'];
        },
        selectedClient() {
            let selectedClient = this.$store.getters['portfolioHub/getSelectedClient'];
            return selectedClient;
        },
        showPortfolioHubMenu() {
            // if(this.selectedClient && this.selectedClient.id !== '' && this.userCapability >=2 && this.userCapability != 6) 
            if(this.selectedClient && this.selectedClient.id !== '') 
                return true;
            else 
                return false;
        },
        paiCalculated() {
            return this.$store.getters['portfolioHub/getPAICalculated'];
        },
        calculatingPAI() {
            return this.$store.getters['pai/getCalculatingPAI'];
        },
        calculatingPAIError() {
            return this.$store.getters['pai/getCalculationError'];
        }
    },
    methods: {
        reverseArrow(a){
            if(a === "datahub"){
                if(this.datahubArrow === true){
                    this.datahubArrow = false;
                }
                else{
                    this.datahubArrow = true;
                }
            }
            else if(a === "custo"){
                if(this.datahubCusto === true){
                    this.datahubCusto = false;
                }
                else{
                    this.datahubCusto = true;
                }
            }
        },
        isChildActive(children) {
            let active = children.find(child => this.currentRoutePath.includes(child.url))
            if (active) {
                return true;
            }
            else {
                return false;
            }
        },
        isParentRouteActive(parentRoute) {
            if (this.currentRoutePath.includes(parentRoute)) 
                return true;
            else
                return false;
        },
        uploadUserImg(files, email) {
            if(files.length > 0) {
                let fileExt = files[0].name.substr(files[0].name.lastIndexOf('.') + 1); 
                if (fileExt == "svg" || fileExt == "png" || fileExt == "jpeg" || fileExt == "jpg") {
                    this.getBase64(files[0])
                        .then(data => {
                            this.$store.dispatch('profile/uploadUserImg', {userId: email, base64img: data})
                        }).catch(err => {
                            this.toast.error(this.$t('alert.error.anErrorHasOccurred'));
                            throw err;
                        });
                    }
                else {
                    this.toast.error(this.$t('alert.error.wrongImageFormat'));
                }

            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error)
            })
        },
        triggerUpload() {
            document.getElementById('user-profile-image').click();
        },
        checkCapabilities(check) {
          this.$store.dispatch('setCheckingCapabilityStatus', check);
        },
        logout() {
            signOut()
                .then(() => {
                    sessionStorage.removeItem('access_level');
                    this.$store.dispatch('profile/logout');
                    this.$router.push('/login');
                });
        },
        SetLocale(locale) {
            document.cookie = "lang=" + locale + "; domain=physisinvestment.com";
            this.$i18n.locale = locale;
        },
        shareClient(){
          this.clientShareStatus = -1;
          if (!this.paiCalculated) {
            this.toast.info(this.$t('alert.error.shareAvailableAfterPAI'), {timeout: false, id: 'calculating-pai-toast', closeButton: false, closeOnClick: false,});
            this.$store.dispatch('pai/calculatePAI', this.selectedClient.id);
            this.sharedClientTriggered = true;
          }
          else {
            try{
                var client = this.selectedClient;
                this.$store.dispatch('shareClient/shareClient', client.id)
                let shareClientUrl = this.$router.resolve({
                    name: 'Share Client',
                    params: { id: client.id }
                }).href;
                    copyText( window.location.origin + shareClientUrl, undefined, (error) => {
                        if (error) {
                            this.clientShareStatus = 2;
                        } else {
                            this.clientShareStatus = 1;
                        }
                    })
                
            }catch (err){
                this.clientShareStatus = 2;
            }
            setTimeout(() => {
                this.clientShareStatus = 0;
            }, 5000);
            console.log('here')
          }
        },
        checkCollapsables(collapse) { 
            if (!this.sharingClient) {
                if (collapse === 'portfoliohubCollapse') {
                    // TODO: removed capabilities on portfolioHub
                    // this.checkCapabilities(this.userCapability < 2 || this.userCapability == 6);
                    if(this.$refs[collapse] === null || !this.$refs[collapse]?.classList.contains('collapsed')) {
                        this.$refs.datahubCollapse.classList.add('collapsed');
                        this.$refs.datahubMenu.classList.remove('show');
                    }
                }
                else if (collapse === null) {
                    if(this.$refs.portfoliohubCollapse && !this.$refs.portfoliohubCollapse?.classList.contains('collapsed')) {
                        this.$refs.portfoliohubCollapse.classList.add('collapsed');
                        this.$refs.portfolioHubMenu.classList.remove('show');
                    }
                    if(this.$refs.datahubCollapse && !this.$refs.datahubCollapse?.classList.contains('collapsed')) {
                        this.$refs.datahubCollapse.classList.add('collapsed');
                        this.$refs.datahubMenu.classList.remove('show');
                    }
                }
                else {
                    if(this.$refs[collapse] !== null && !this.$refs[collapse]?.classList.contains('collapsed')) {
                        if (this.$refs.portfoliohubCollapse) {
                            this.$refs.portfoliohubCollapse.classList.add('collapsed');
                            this.$refs.portfolioHubMenu.classList.remove('show');
                        }
                    }
                }
            }
        },
        triggerMethods(collapse, accessLv) {
            console.log('triggerMethods', this.userCapability, accessLv);
            if(!this.$isDemoPortfolio()) {
                this.checkCapabilities(this.userCapability < accessLv);
                this.checkCollapsables(collapse)
                if(this.$refs[collapse].classList.contains('collapsed')) {
                    this.$refs[collapse].click();
                }
            }
        }
    },
    components: {
      TopNavigation
    },
    watch: {
        selectedClient(newVal) {
            if(newVal && newVal.id !== '' && !this.sharingClient) {
                this.clientShareStatus = 0;
                setTimeout(() => {
                    this.$refs.datahubCollapse.classList.add('collapsed');
                    this.$refs.datahubMenu.classList.remove('show');
                    this.$refs.portfoliohubCollapse.click();
                });
            }
        },
        paiCalculated(newVal) {
            if (newVal) {
                if (this.sharedClientTriggered) {
                    const content = {
                        component: ToastNavigation,
                        props: {
                            url: null,
                            action: 'shareClient',
                            actionMsg: 'content.here',
                            msg: 'alert.error.calculationCompleteShare'
                        },
                        listeners: {
                            shareClient: () => {
                                this.shareClient();
                                this.toast.dismiss('pai-calculated-share');
                            }
                        }
                    }
                    this.toast.dismiss('calculating-pai-toast');
                    setTimeout(() => {
                        this.toast.info(content, {timeout: false, id: 'pai-calculated-share', closeButton: "button", closeOnClick: false,}); 
                    }, 500);

                    this.sharedClientTriggered = false;
                }
                else {
                    this.clientShareStatus = 0;
                }
                if(this.clientShareStatus == -1)
                    this.toast.dismiss('calculating-pai-toast');
            }
        },
        calculatingPAIError(newVal) {
            if (newVal) {
                this.toast.dismiss('calculating-pai-toast');
                this.clientShareStatus = 0;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

$blue: #00A0FF;
$dark-blue: #047CE5;
$teal: #3DA89C;
$red: #F9645B;

.textNav{
    color: #0d6efd  !important;

}
#user-side-menu {
    .mobileNav{
        overflow-x: auto;

    }
    @media screen and (min-width: 992px) {
        .mobile-navbar{
            flex-direction: column;
        }
        
    }
    @media screen and (max-width: 991px){
        .mobile-navbar{
            flex-direction: column;
        } 
    }
.btn-large {
    font-family: Poppins-Bold;

}
    .avatar-container, .avatar, .overlay {
        max-width: 112px;
        min-width: 112px;
        max-height: 112px;
        min-height: 112px;
    }
    &.card{
        box-shadow: unset;
        background: #fff;
    }
    .logo-container {
        img {
            max-width: 112px;
        }
    }
    .overlay {
        .icon {
            &.fa-circle-notch {
                top: 36% !important;
                left: 36% !important;
            }
        }
    }
    .avatar-container {
        #user-profile-image {
            display: none;
        }
    }
    #datahubMenu, #portfolioHubMenu {
        :deep(.menu-link) {
            font-size: 0.875rem;
            text-decoration: none;
            &.active {
                span {
                    background: -webkit-linear-gradient(#00A0FF 0%, #047CE5 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: 'Poppins-Bold';
                    position: relative;
                    text-decoration: none;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 3px;
                        left: 0;
                        height: 1px;
                        width: 100%;
                        background: $blue;
                        background: -webkit-linear-gradient($blue 0%, $dark-blue 100%);
                    }
                }
            }
            &.category {
                opacity: 0.65;
            }
        }
    }
    .logout {
        a {
            color: #293644;
        }
    }
    .collapsed {
        .icon {
            transform: rotate(0deg) !important;
        }
    }
    :not(.collapsed) {
        .icon {
            transform: rotate(180deg);
        }
    }
    .sub {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    .extras {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        position: absolute;
        bottom: 0;
        .menu-link {
            font-size: 0.875rem;
        }
    }
    .link-message{
        &.teal{
            color: $teal!important;
        }
        &.red{
            color: $red!important;
        }
    }
    #full-username {
        font-size: 1.125rem;
    }
    :deep(.menu-link) {
        font-size: 1rem;
        text-decoration: none;
        &.active {
            span {
                background: #00A0FF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: 'Poppins-Bold';
                position: relative;
                text-decoration: none;

            }
        }
        &.inactive {
            span {
                color: #293644;
            }
        }
        &.btn-large{
            max-width: clamp(130px, 248px, 100%);
        }
        .icon {
            color: #293644;
        }
        &.share {
            &.disabled {
                pointer-events: none;
                cursor: pointer;
                opacity: .65;
            }
            color: #293644;
        }
        @media screen and (min-width: 992px) {
            .icon {
                transform: rotate(180deg);
            }
        }
        @media screen and (max-width: 991px) {
            &.btn-primary {
                .icon {
                    color: #FFFFFF;
                }
            }
             &.btn-outline-primary {
                .icon {
                    color: #293644;
                }
            }
        }
    }
    @media screen and (min-width: 900px) {
        #supportCTA-wrapper{
            display: flex !important;
            margin: 0 auto;
            #supportCTA{
                .mobile {
                    display: none;
                }
                span {
                    text-transform: capitalize;
                    text-decoration: underline;
                    color: #00A0FF;
                }
                svg{
                    height: 1rem;
                    width: 1rem;
                    padding: 2px;
                    margin-bottom: -2px;
                    border: 1px solid;
                    
                }
            }
        }
    }
    @media screen and (max-width: 899px) {
        #supportCTA-wrapper {
            display: none;
        }
    }
    :deep{
      [aria-expanded='true']{
        +{
          #top-navigation-dropdown-datahub-mobile,
          #top-navigation-dropdown-profile-mobile,
          #top-navigation-dropdown-customization-mobile{
            position: relative!important;
            inset: unset!important;
            transform: unset!important;
            border: none;
            max-height: unset;
            padding: 16px 0 8px!important
          }
        }
      }
      .dropdown-divider{
        width: 100%;
        border-top-color: #E6E6E6!important;
      }
    }
}

</style>

import {getSessionToken} from '../http/auth'
import {API} from '../http/api'
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();

const pai = {
    namespaced: true,
    state: {
        PAIIndicators: {
            portfolio: [],
            benchmark: []
        },
        PAIIndicators2: {
            portfolio: [],
            benchmark: []
        },
        PAIIndicators3: {
            portfolio: [],
            benchmark: []
        },
        errorPAI: false,
        loadingPAIIndicators: false,
        paiDetail: {
            portfolio: {
                companies: [],
                indicators: [],
                find: "",
                order: "asc",
                orderBy: "value",
                page: 1,
                perPage: 20,
                total: 1
            },
            benchmark: {
                companies: [],
                indicators: [],
                find: "",
                order: "asc",
                orderBy: "value",
                page: 1,
                perPage: 20,
                total: 1
            },
        },
        paiDetailLoading: false,
        paiDetailError: false,
        paiDetailLoadingResults: false,
        minPAIYear: undefined,
        paiProgress: 0,
        calculatingPAI: false,
        lastUpdated: undefined,
        calculationError: false
    },
    mutations: {
        setPAIIndicators(state, payload) {
            state.PAIIndicators = payload;
        },
        setPAIIndicators2(state, payload) {
            state.PAIIndicators2 = payload;
        },
        setPAIIndicators3(state, payload) {
            state.PAIIndicators3 = payload;
        },
        setLoadingPAIIndicators(state, payload) {
            state.loadingPAIIndicators = payload;
        },
        setErrorPAI(state, payload) {
            state.errorPAI = payload;
        },
        setPAIDetailLoading(state, payload) {
            state.paiDetailLoading = payload;
        },
        setPAIDetailError(state, payload) {
            state.paiDetailError = payload;
        },
        setPAIDetailLoadingResults(state, payload) {
            state.paiDetailLoadingResults = payload;
        },
        setPAIDetail(state, payload) {
            state.paiDetail = payload;
        },
        setPAIDetailPortfolio(state, payload) {
            state.paiDetail.portfolio = payload;
        },
        setPAIDetailBenchmark(state, payload) {
            state.paiDetail.benchmark = payload;
        },
        setMinPAIYear(state, payload) {
            state.minPAIYear = payload;
        },
        setPAIProgress(state, payload) {
            state.paiProgress = payload;
        },
        setCalculatingPAI(state, payload) {
            state.calculatingPAI = payload;
        },
        setCalculationError(state, payload) {
            state.calculationError = payload;
        },
    },
    actions: {
        async getPAIIndicators({commit, dispatch}, data) {
            commit('setErrorPAI', false);
            commit('setLoadingPAIIndicators', true);
            API.get(
                'getPAIIndicatorsData' + (await getSessionToken() === null ? 'Shared' : '') + data.number,
                data.id ? data.id : '',
                {
                  headers: {
                    Authorization: await getSessionToken()
                  }
                }
            ).then(res => {
              commit('setPAIIndicators'+data.number, res);
              setTimeout(() => {
                commit('setLoadingPAIIndicators', false);
              }, 1000);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setErrorPAI', true);
                    commit('setLoadingPAIIndicators', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;      
                }
            })
        },
        clearPAIIndicatorsData({commit}) {
            commit('setPAIIndicators', []);
            commit('setPAIIndicators2', []);
            commit('setPAIIndicators3', []);
            commit('setErrorPAI', false);
        },
        async getPAIDetailInitial({commit, dispatch}, data) {
            commit('setPAIDetailError', false);
            let paiDetailParameters = this.getters["pai/getPAIDetailPortfolioData"];
            commit('setPAIDetailLoading', true);
            API.get(
                'getPAIDetail'+(await getSessionToken() === null ? 'Shared' : '') + data.number,
                `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : paiDetailParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${false}`,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(async reply => {
                if (reply.page > 1) {
                    paiDetailParameters.companies = [...paiDetailParameters.companies, ...reply.companies];
                } else {
                    paiDetailParameters.companies = reply.companies;
                }
                let companySample = paiDetailParameters.companies[0];
                let minYear = undefined;
                if (companySample) {
                    for (const key in companySample) {
                        if (key.toLowerCase() !== 'name' && key.toLowerCase() !== 'weight' && key.toLowerCase() !== 'security') {
                            let year = key.split('-')[0] * 1;
                            if (minYear === undefined) {
                                minYear = year;
                            }
                            else {
                                if (minYear > year) {
                                    minYear = year;    
                                }
                            }
                        }
                    }
                }
                // Quarters
                paiDetailParameters.companies.forEach(company => {
                    for (const key in company) {
                        if (key !== 'name' && key !== 'weight') {
                            if (company[key]?.quarters && company[key]?.quarters.length > 0) {
                                company[key].quarters.forEach(qt => {
                                    company[key + '-Q' + qt.number] = {
                                        value: qt.value
                                    }
                                })
                            }
                        }
                    }
                });
                commit('setMinPAIYear', minYear);
                paiDetailParameters.indicators = reply.indicators;
                paiDetailParameters.find = reply.find;
                paiDetailParameters.order = reply.order;
                paiDetailParameters.orderBy = reply.orderBy;
                paiDetailParameters.page = reply.page;
                paiDetailParameters.perPage = reply.perPage;
                paiDetailParameters.total = reply.total;
                commit('setPAIDetailPortfolio', paiDetailParameters);
                if (data.benchmark) {
                    let paiDetailParametersB = this.getters["pai/getPAIDetailBenchmarkData"];
                    API.get(
                        'getPAIDetail'+(await getSessionToken() === null ? 'Shared' : '') + data.number,
                        `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : paiDetailParametersB.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${true}`,
                        {
                            headers: {
                                Authorization: await getSessionToken()
                            }
                        }
                    ).then(replyB => {
                        if (replyB.page > 1) {
                            paiDetailParametersB.companies = [...paiDetailParametersB.companies, ...replyB.companies];
                        } else {
                            paiDetailParametersB.companies = replyB.companies;
                        }
                        paiDetailParametersB.companies.forEach(company => {
                            for (const key in company) {
                                if (key !== 'name' && key !== 'weight') {
                                    if (company[key]?.quarters && company[key]?.quarters.length > 0) {
                                        company[key].quarters.forEach(qt => {
                                            company[key + '-Q' + qt.number] = {
                                                value: qt.value
                                            }
                                        })
                                    }
                                }
                            }
                        });
                        paiDetailParametersB.indicators = replyB.indicators;
                        paiDetailParametersB.find = replyB.find;
                        paiDetailParametersB.order = replyB.order;
                        paiDetailParametersB.orderBy = replyB.orderBy;
                        paiDetailParametersB.page = replyB.page;
                        paiDetailParametersB.perPage = replyB.perPage;
                        paiDetailParametersB.total = replyB.total;
                        commit('setPAIDetailBenchmark', paiDetailParametersB);
                        commit('setPAIDetailLoading', false);
                    }).catch(err => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            commit('setPAIDetailLoading', false);
                            toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                            commit('setPAIDetailError', false);
                            throw err;    
                        }
                    })
                }
                else {
                    commit('setPAIDetailLoading', false);
                }
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setPAIDetailLoading', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setPAIDetailError', false);
                    throw err;    
                }
            })
        },
        async getPAIDetail({commit, dispatch}, data) {
            commit('setPAIDetailError', false);
            let paiDetailParameters = data.benchmark ? this.getters["pai/getPAIDetailBenchmarkData"] : this.getters["pai/getPAIDetailPortfolioData"];
            commit('setPAIDetailLoadingResults', true);
            API.get(
                'getPAIDetail'+(await getSessionToken() === null ? 'Shared' : '') + data.number,
                `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : paiDetailParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${data.benchmark}`,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(reply => {
                if (reply.page > 1) {
                    paiDetailParameters.companies = [...paiDetailParameters.companies, ...reply.companies];
                } else {
                    paiDetailParameters.companies = reply.companies;
                }
                // Quarters
                paiDetailParameters.companies.forEach(company => {
                    for (const key in company) {
                        if (key !== 'name' && key !== 'weight') {
                            if (company[key]?.quarters && company[key]?.quarters.length > 0) {
                                company[key].quarters.forEach(qt => {
                                    company[key + '-Q' + qt.number] = {
                                        value: qt.value
                                    }
                                })
                            }
                        }
                    }
                });
                paiDetailParameters.indicators = reply.indicators;
                paiDetailParameters.find = reply.find;
                paiDetailParameters.order = reply.order;
                paiDetailParameters.orderBy = reply.orderBy;
                paiDetailParameters.page = reply.page;
                paiDetailParameters.perPage = reply.perPage;
                paiDetailParameters.total = reply.total;
                data.benchmark ? commit('setPAIDetailBenchmark', paiDetailParameters) : commit('setPAIDetailPortfolio', paiDetailParameters);
                commit('setPAIDetailLoadingResults', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setPAIDetailLoadingResults', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setPAIDetailError', false);
                    throw err;    
                }
            })
        },
        clearPAIDetail({commit}) {
            let paiParameters = {
                portfolio: {
                    companies: [],
                    indicators: [],
                    find: "",
                    order: "asc",
                    orderBy: "value",
                    page: 1,
                    perPage: 20,
                    total: 1
                },
                benchmark: {
                    companies: [],
                    indicators: [],
                    find: "",
                    order: "asc",
                    orderBy: "value",
                    page: 1,
                    perPage: 20,
                    total: 1
                },
            }
            commit('setPAIDetail', paiParameters);
            commit('setMinPAIYear', undefined);
            commit('setPAIDetailError', false);
        },
        async setLastUpdatedPAI({commit, dispatch}, id) {
            API.post(
                'setPAIUpdateDate',
                id ? id : '',
                {
                  headers: {
                    Authorization: await getSessionToken()
                  }
                }
            ).then(() => {
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoadingPAIIndicators', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;      
                }
            })
        },
        async calculatePAI({commit, dispatch}, id) {
            commit('setCalculationError', false);
            commit('setCalculatingPAI', true);
            let auth = {
                headers: {
                    Authorization: await getSessionToken()
                }
            };
            dispatch(id ? 'portfolioHub/setPAICalculated' : 'dataHub/setPAICalculated', false, { root: true });
            API.get('getExchangeRate', '', auth)
            .then( async () => {
                let endpoint = 'calculatePAIIndicator';
                let endpoint2 = 'calculatePAIIndicator2';
                let endpoint3 = 'calculatePAIIndicator3';
                API.post(endpoint, '1' + (id ? '/' + id : ''), auth)
                    .then(async () => {
                        commit('setPAIProgress', 0);
                        let auth = {
                            headers: {
                                Authorization: await getSessionToken()
                            }
                        };
                        API.post(endpoint, '2' + (id ? '/' + id : ''), auth)
                            .then(async () => {
                                commit('setPAIProgress', 2);
                                let auth = {
                                    headers: {
                                        Authorization: await getSessionToken()
                                    }
                                };
                                API.post(endpoint, '12' + (id ? '/' + id : ''), auth)
                                    .then(async () => {
                                        commit('setPAIProgress', 4);
                                        let auth = {
                                            headers: {
                                                Authorization: await getSessionToken()
                                            }
                                        };
                                        API.post(endpoint, '4' + (id ? '/' + id : ''), auth)
                                            .then(async () => {
                                                commit('setPAIProgress', 6);
                                                let auth = {
                                                    headers: {
                                                        Authorization: await getSessionToken()
                                                    }
                                                };
                                                API.post(endpoint, '5' + (id ? '/' + id : ''), auth)
                                                    .then(async () => {
                                                        commit('setPAIProgress', 8);
                                                        let auth = {
                                                            headers: {
                                                                Authorization: await getSessionToken()
                                                            }
                                                        };
                                                        API.post(endpoint, '6' + (id ? '/' + id : ''), auth)
                                                            .then(async () => {
                                                                commit('setPAIProgress', 10);
                                                                let auth = {
                                                                    headers: {
                                                                        Authorization: await getSessionToken()
                                                                    }
                                                                };
                                                                API.post(endpoint, '3' + (id ? '/' + id : ''), auth)
                                                                    .then(async () => {
                                                                        commit('setPAIProgress', 12);
                                                                        let auth = {
                                                                            headers: {
                                                                                Authorization: await getSessionToken()
                                                                            }
                                                                        };
                                                                        API.post(endpoint, '8' + (id ? '/' + id : ''), auth)
                                                                            .then(async () => {
                                                                                commit('setPAIProgress', 14);
                                                                                let auth = {
                                                                                    headers: {
                                                                                        Authorization: await getSessionToken()
                                                                                    }
                                                                                };
                                                                                API.post(endpoint, '9' + (id ? '/' + id : ''), auth)
                                                                                    .then(async () => {
                                                                                        commit('setPAIProgress', 16);
                                                                                        let auth = {
                                                                                            headers: {
                                                                                                Authorization: await getSessionToken()
                                                                                            }
                                                                                        };
                                                                                        API.post(endpoint, '14' + (id ? '/' + id : ''), auth)
                                                                                            .then(async () => {
                                                                                                commit('setPAIProgress', 18);
                                                                                                let auth = {
                                                                                                    headers: {
                                                                                                        Authorization: await getSessionToken()
                                                                                                    }
                                                                                                };
                                                                                                API.post(endpoint, '11' + (id ? '/' + id : ''), auth)
                                                                                                    .then(async () => {
                                                                                                        commit('setPAIProgress', 20);
                                                                                                        let auth = {
                                                                                                            headers: {
                                                                                                                Authorization: await getSessionToken()
                                                                                                            }
                                                                                                        };
                                                                                                        API.post(endpoint, '10' + (id ? '/' + id : ''), auth)
                                                                                                            .then(async () => {
                                                                                                                commit('setPAIProgress', 22);
                                                                                                                let auth = {
                                                                                                                    headers: {
                                                                                                                        Authorization: await getSessionToken()
                                                                                                                    }
                                                                                                                };
                                                                                                                API.post(endpoint, '13' + (id ? '/' + id : ''), auth)
                                                                                                                    .then(async () => {
                                                                                                                        commit('setPAIProgress', 24);
                                                                                                                        let auth = {
                                                                                                                            headers: {
                                                                                                                                Authorization: await getSessionToken()
                                                                                                                            }
                                                                                                                        };
                                                                                                                        API.post(endpoint, '7' + (id ? '/' + id : ''), auth)
                                                                                                                            .then(async () => {
                                                                                                                                commit('setPAIProgress', 26);
                                                                                                                                let auth = {
                                                                                                                                    headers: {
                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                    }
                                                                                                                                };
                                                                                                                                API.post(endpoint, '15' + (id ? '/' + id : ''), auth)
                                                                                                                                    .then(async () => {
                                                                                                                                        commit('setPAIProgress', 28);
                                                                                                                                        let auth = {
                                                                                                                                            headers: {
                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                            }
                                                                                                                                        };
                                                                                                                                        API.post(endpoint, '16' + (id ? '/' + id : ''), auth)
                                                                                                                                            .then(async () => {
                                                                                                                                                commit('setPAIProgress', 30);
                                                                                                                                                let auth = {
                                                                                                                                                    headers: {
                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                    }
                                                                                                                                                };
                                                                                                                                                API.post(endpoint, '20' + (id ? '/' + id : ''), auth)
                                                                                                                                                    .then(async () => {
                                                                                                                                                        commit('setPAIProgress', 32);
                                                                                                                                                        let auth = {
                                                                                                                                                            headers: {
                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                            }
                                                                                                                                                        };
                                                                                                                                                        API.post(endpoint, '30' + (id ? '/' + id : ''), auth)
                                                                                                                                                            .then(async () => {
                                                                                                                                                                commit('setPAIProgress', 34);
                                                                                                                                                                let auth = {
                                                                                                                                                                    headers: {
                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                    }
                                                                                                                                                                };
                                                                                                                                                                API.post(endpoint, '40' + (id ? '/' + id : ''), auth)
                                                                                                                                                                    .then(async () => {
                                                                                                                                                                        commit('setPAIProgress', 36);
                                                                                                                                                                        API.post(endpoint2, 1 + (id ? '/' + id : ''), auth)
                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                commit('setPAIProgress', 38);
                                                                                                                                                                                let auth = {
                                                                                                                                                                                    headers: {
                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                    }
                                                                                                                                                                                };
                                                                                                                                                                                API.post(endpoint2, '2' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                        commit('setPAIProgress', 40);
                                                                                                                                                                                        let auth = {
                                                                                                                                                                                            headers: {
                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                            }
                                                                                                                                                                                        };
                                                                                                                                                                                        API.post(endpoint2, '3' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                commit('setPAIProgress', 42);
                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                    }
                                                                                                                                                                                                };
                                                                                                                                                                                                API.post(endpoint2, '4' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                        commit('setPAIProgress', 44);
                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                            }
                                                                                                                                                                                                        };
                                                                                                                                                                                                        API.post(endpoint2, '5' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                commit('setPAIProgress', 46);
                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                };
                                                                                                                                                                                                                API.post(endpoint2, '6' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                        commit('setPAIProgress', 48);
                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        };
                                                                                                                                                                                                                        API.post(endpoint2, '7' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                commit('setPAIProgress', 50);
                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                API.post(endpoint2, '8' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                        commit('setPAIProgress', 52);
                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                        API.post(endpoint2, '9' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                commit('setPAIProgress', 54);
                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                API.post(endpoint2, '13' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                        commit('setPAIProgress', 56);
                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                        API.post(endpoint2, '14' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                commit('setPAIProgress', 58);
                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                API.post(endpoint3, '1' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                        commit('setPAIProgress', 60);
                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                        API.post(endpoint3, '2' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                commit('setPAIProgress', 62);
                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                API.post(endpoint3, '3' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 64);
                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                        API.post(endpoint3, '4' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 66);
                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                API.post(endpoint3, '5' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 68);
                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '6' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 70);
                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '7' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 72);
                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '8' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 74);
                                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '9' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 76);
                                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '10' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 78);
                                                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '11' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 84);
                                                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '14' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 86);
                                                                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '15' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 88);
                                                                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '18' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 90);
                                                                                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '19' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 92);
                                                                                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '20' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 94);
                                                                                                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '21' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 96);
                                                                                                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '22' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 98);
                                                                                                                                                                                                                                                                                                                                                                                                                let auth = {
                                                                                                                                                                                                                                                                                                                                                                                                                    headers: {
                                                                                                                                                                                                                                                                                                                                                                                                                        Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                };
                                                                                                                                                                                                                                                                                                                                                                                                                API.post(endpoint3, '23' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                                                                    .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                                                        commit('setPAIProgress', 99);
                                                                                                                                                                                                                                                                                                                                                                                                                        let auth = {
                                                                                                                                                                                                                                                                                                                                                                                                                            headers: {
                                                                                                                                                                                                                                                                                                                                                                                                                                Authorization: await getSessionToken()
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                        };
                                                                                                                                                                                                                                                                                                                                                                                                                        API.post(endpoint3, '24' + (id ? '/' + id : ''), auth)
                                                                                                                                                                                                                                                                                                                                                                                                                            .then(async () => {
                                                                                                                                                                                                                                                                                                                                                                                                                                commit('setPAIProgress', 100);
                                                                                                                                                                                                                                                                                                                                                                                                                                commit('setCalculatingPAI', false);
                                                                                                                                                                                                                                                                                                                                                                                                                                let updatedDate = new Date().toISOString().slice(0, -1);
                                                                                                                                                                                                                                                                                                                                                                                                                                if(id) {
                                                                                                                                                                                                                                                                                                                                                                                                                                    dispatch('portfolioHub/setLastUpdatedPAI', updatedDate, {root: true});
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                dispatch(id ? 'portfolioHub/setPAICalculated' : 'dataHub/setPAICalculated', true, { root: true });
                                                                                                                                                                                                                                                                                                                                                                                                                                dispatch('setLastUpdatedPAI', id)
                                                                                                                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                            })
                                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                    })
                                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                            })
                                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                                            });
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                                    });
                                                                                                                                                                                                            })
                                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                                            });
                                                                                                                                                                                                    })
                                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                                    });
                                                                                                                                                                                            })
                                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                                            });
                                                                                                                                                                                    })
                                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                                    });
                                                                                                                                                                            })
                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                                            });
                                                                                                                                                                    })
                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                                    });
                                                                                                                                                            })
                                                                                                                                                            .catch((err) => {
                                                                                                                                                                dispatch('catchError', err);
                                                                                                                                                            });
                                                                                                                                                    })
                                                                                                                                                    .catch((err) => {
                                                                                                                                                        dispatch('catchError', err);
                                                                                                                                                    });
                                                                                                                                            })
                                                                                                                                            .catch((err) => {
                                                                                                                                                dispatch('catchError', err);
                                                                                                                                            });
    
                                                                                                                                    })
                                                                                                                                    .catch((err) => {
                                                                                                                                        dispatch('catchError', err);
                                                                                                                                    });
                                                                                                                            })
                                                                                                                            .catch((err) => {
                                                                                                                                dispatch('catchError', err);
                                                                                                                            });
                                                                                                                    })
                                                                                                                    .catch((err) => {
                                                                                                                        dispatch('catchError', err);
                                                                                                                    });
                                                                                                            })
                                                                                                            .catch((err) => {
                                                                                                                dispatch('catchError', err);
                                                                                                            });
                                                                                                    })
                                                                                                    .catch((err) => {
                                                                                                        dispatch('catchError', err);
                                                                                                    });
                                                                                            })
                                                                                            .catch((err) => {
                                                                                                dispatch('catchError', err);
                                                                                            });
                                                                                    })
                                                                                    .catch((err) => {
                                                                                        dispatch('catchError', err);
                                                                                    });
                                                                            })
                                                                            .catch((err) => {
                                                                                dispatch('catchError', err);
                                                                            });
                                                                    })
                                                                    .catch((err) => {
                                                                        dispatch('catchError', err);
                                                                    });
                                                            })
                                                            .catch((err) => {
                                                                dispatch('catchError', err);
                                                            });
                                                    })
                                                    .catch((err) => {
                                                        dispatch('catchError', err);
                                                    });
                                            })
                                            .catch((err) => {
                                                dispatch('catchError', err);
                                            });
                                    })
                                    .catch((err) => {
                                        dispatch('catchError', err);
                                    });
                            })
                            .catch((err) => {
                                dispatch('catchError', err);
                            });
                    })
                    .catch((err) => {
                        dispatch('catchError', err);
                    });
            }).catch((err) => {
                dispatch('catchError', err);
            });
        },
        catchError({commit, dispatch}, err) {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                commit('setCalculationError', true);
                commit('setCalculatingPAI', false);
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;
            }
        },
        resetPAIProgress({commit}, data) {
            commit('setPAIProgress', data);
        }
    },
    getters: {
        getPAIIndicatorsData: state => {
            return { 
              principal: state.PAIIndicators, 
              environmental: state.PAIIndicators2, 
              additional: state.PAIIndicators3 };
        },
        getLoadingPAIIndicators: state => {
            return state.loadingPAIIndicators;
        },
        getErrorPAI: state => {
            return state.errorPAI;
        },
        getPaiDetailLoading: state => {
            return state.paiDetailLoading;
        },
        getPAIDetailLoadingResults: state => {
            return state.paiDetailLoadingResults;
        },
        getPaiDetailError: state => {
            return state.paiDetailError;
        },
        getPAIDetailPortfolioData: state => {
            return state.paiDetail.portfolio;
        },
        getPAIDetailBenchmarkData: state => {
            return state.paiDetail.benchmark;
        },
        getMinPAIYear: state => {
            return state.minPAIYear;
        },
        getPAIProgress: state => {
            return state.paiProgress;
        },
        getCalculatingPAI: state => {
            return state.calculatingPAI;
        },
        getCalculationError: state => {
            return state.calculationError;
        }
    }
}

export default pai